@import "../../../../scss/colors";

.PersonalOrderItem {
  &_container {
    font-weight: 500;
    position: relative;
    background: #fff;
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.04);
    //border-bottom-left-radius: 5px;
    //border-bottom-right-radius: 5px;
    @media screen and (max-width: 575px) {
      padding: 12px;
      font-size: .8rem !important;
    }


  }

  &_delivery-head {
    display: flex;
    justify-content: space-between;
    font-size: .9rem;

    div {
      color: $main-1-2;
    }
  }

  &_delivery-body {
    display: flex;
    justify-content: space-between;
    font-size: .9rem;
  }

  &_container-sub {
    //opacity: 0;

    //&.entering {
    //  animation: slideDown .3s ease-out forwards;
    //}
    //
    //&.entered {
    //  opacity: 1;
    //}
    //
    //&.exiting {
    //  animation: slideUp .3s ease-out forwards;
    //}
    //
    //&.exited {
    //  opacity: 0;
    //}
  }

}
