.UI {
  &__Button {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0;
    background: #88f61b;
    border-radius: 39px;
    padding: 14px 38px;
    cursor: pointer;
    transition: .15s ease-in;

    &:hover {
      box-shadow: 0 3px 15px 3px rgba(0, 34, 86, 0.12);
    }
  }

  &__Tab {
    background: #5D20C1;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    transition: 0.25s ease-in;
    @media screen and (max-width: 575px) {
      padding: 0 0 0 15px;
     justify-content: left;
    }

    &:not(:last-child) {
      margin-right: 1px;
    }

    &-title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #FFFFFF;
      margin-left: 10px;
      transition: 0.25s ease-in;
      @media screen and (max-width: 575px) {
        font-size: 12px;
        line-height: 15px;
      }
    }

    &-circle {
      border: 1px solid #FFFFFF;
      border-radius: 10px;
      width: 20px;
      height: 20px;
      position: relative;
      transition: 0.25s ease-in;

      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 12px;
        height: 12px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #5D20C1;
        border-radius: 50%;
      }
    }

    &.active {
      background: #fff;
      box-shadow: inset 0px -2px 20px rgba(92, 69, 145, 0.1);

      .UI__Tab {
        &-circle {
          border: 1px solid #5D20C1;
        }

        &-title {
          color: #5D20C1;
        }
      }
    }
  }

  &__RadioTabs {
    &-title {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #8594A2;
      &_small {
        font-size: 10px;
      }
    }

    &-tabs {
      display: flex;
      align-items: center;
      margin: 6px 0 0;
      @media screen and (max-width: 575px) {
        width: 100%;
        flex-wrap: wrap;
        margin: 0!important;
        justify-content: space-between;
        > div {
          min-width: 45%;
          padding: 4px 6px;
          margin: 5px 2%;
        }
      }
    }

    &-tab {
      border: 1px solid #C9D6E1;
      border-radius: 20px;
      padding: 5px 16px;
      margin-right: 0;
      cursor: pointer;
      transition: 0.25s ease-in;
      user-select: none;
      color: #8594A2;
      font-style: normal;
      font-weight: normal;
      text-align: center;

      &:not(:last-child) {
        margin-right: 8px;
      }

      &_active {
        color: #fff;
        background: #7B53DA;
      }
    }

    @media screen and (max-width: 1500px) {
      &-title {
        font-size: 10px;
        &_small {
          font-size: 8px;
        }
      }

      &-tabs {
        display: flex;
        align-items: center;
        margin: 5px 0 0;
      }

      &-tab {
        padding: 4px 12px;
        font-size: 12px;

        &:not(:last-child) {
          margin-right: 6px;
        }

      }
    }
  }

  &__SwitchCondition {
    display: flex;
    flex-direction: column;

    &-title {
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      display: flex;
      align-items: flex-end;
      color: #8594A2;
    }

    &-name {
      color: #8594A2;

      &_active {
        color: #000;
      }
    }

    &-switch {
      background: #7B53DA;
      border: 1px solid #7B53DA;
      box-sizing: border-box;
      border-radius: 60px;
      width: 37px;
      height: 20px;
      margin: 0 6px;
      display: flex;
      align-items: center;
      padding: 0 2px;
      cursor: pointer;
        justify-content: flex-end;

      &_active {
        justify-content: flex-start;
      }

      &-circle {
        background: #FFFFFF;
        border-radius: 60px;
        width: 16px;
        height: 16px;
      }
    }
  }
}