.Total {
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1rem;

  div {
    font-weight: bold;
    //padding: 0 15px 0 0;
  }

  @media screen and (max-width: 575px) {
    flex-wrap: wrap;
    font-size: 1rem;
  }

}
.Sum{
  color: #7F5CD4;
}