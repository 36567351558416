.LittleLoader {
  width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 50px;
    height: 50px;
  }

  //style="margin: auto; background: none; display: block; shape-rendering: auto;" width="144px" height="144px"

}