@import "../../../../../scss/colors";

.PersonalOrderItemTranches {
  position: relative;
  z-index: 99;
  display: flex;
  padding: 0 0 30px 0;

  &_LeftBlock {
    min-width: 30%;
    font-size: .8rem;
    margin-top: 40px;

    &-leftHeadingRotate {
      position: relative;

      &.top {
        align-self: flex-end;
        border-top: 1px solid $main-2-1;
        border-right: 1px solid $main-2-1;
        border-bottom: 1px solid $main-2-1;
        display: flex;
        justify-content: center;
        min-height: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
      }

      &.bottom {
        align-self: flex-start;
        border-bottom: 1px solid $main-2-1;
        border-right: 1px solid $main-2-1;
        display: flex;
        justify-content: center;
        min-height: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
      }

      > div {
        width: 100%;
        padding: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        > div {
          //transform: rotate(-90deg);
          letter-spacing: .5px;
          font-weight: 500;
        }
      }
    }

    &__block {
      padding-left: 10%;
    }
  }

  &_RightBlock {
    padding-top: 40px;
    width: 70%;
    font-size: 0.8rem;
    display: flex;
    justify-content: flex-start;
    flex: 1 0 auto;
    margin-bottom: 0;
    //overflow: hidden;
    //overflow-x: scroll;
    //cursor: grab;


    &__item {
      position: relative;
      min-width: 130px;
      display: flex;
      flex-direction: column;

      &.active {
        .PersonalOrderItemTranches_RightBlock__item {
          &_head {
            &-label {
              position: absolute;
              z-index: 0;
              top: -30px;
              height: 90px;
              width: 100%;
              font-weight: 500;
              display: flex;
              justify-content: center;
              align-items: flex-start;
              color: #292929 !important;

              div {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                padding: 10px;
              }
            }
          }

          &_pay {
            display: flex;
            flex-direction: column;

            &-btn {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 1rem;
              background-color: #88F61B;
              box-shadow: 0px 10px 20px rgba(136, 246, 27, 0.2);
              border-radius: 50px;
              color: #fff;
              font-weight: normal;
              height: 36px;
              margin-top: 10px;
              cursor: pointer;

              &.paid {
                opacity: .5;
                box-shadow: 0px 10px 20px rgba(136, 246, 27, 0);
                cursor: inherit;
              }
            }

            &-sum {

            }
          }
        }
      }

      &_head {
        padding: 0 6px;
        height: 60px;
        position: relative;

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
          padding: 10px 20px;
          margin: 5px 0;
          background-color: #fff;
          box-shadow: 0px 10px 20px rgba(5, 103, 208, 0.2);
          font-size: .8rem;
          position: absolute;
          width: 90%;
          z-index: 100;

          &.reported {
            * {
              color: #fff !important;
            }

            background-color: #8864DA;
            box-shadow: 0px 20px 20px rgba(5, 103, 208, 0.2);

            &:after {
              content: '';
              display: block;
              position: absolute;
              width: 86%;
              height: 12px;
              background-color: #8864DA;
              clip-path: polygon(0 0, 50% 100%, 100% 0);
              bottom: -11.5px;
            }

            .reported-label {
              position: absolute;
              border-radius: 50%;
              width: 22px;
              height: 22px;
              right: -5px;
              top: -5px;

              &.waiting {
                background-color: white;
              }

              &.accepted {
                background-color: #88F61B;
              }

              &.acceptedwithchanges {
                background-color: #FFB800;
              }

              &.expired {
                background-color: #FB3C6C;
              }
            }

          }

          * {
            color: $black-font !important;
          }
        }
      }

      &_total-head {
        //padding: 0 6px;
        height: 60px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        //background-color: rgba(135,100,218,.5);
      }

    }

  }

  &_title {
    color: #8764DA;
  }

}


