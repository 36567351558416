@import "../../../../../scss/colors";

.PersonalOrderItemControls {
  margin: 1rem 0  2rem;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1440px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    height: 38px;
    max-width: fit-content;
    border-radius: 20px;
    font-weight: 500;
    font-size: .9rem;
    text-align: center;
    color: #fff;
    margin: 0.3rem .5rem;
    padding: 0 2.5rem;
    transition: .3s ease-out;
    &-unread {
      background: #00efb2;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #062c33;
    }

    &:hover {
      cursor: pointer;
      text-decoration: none;
      filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.2));
    }

    &.withTooltip {
      padding: 10px 10px 10px 0;
    }

    &:last-child {
      //margin: 0 0 1rem .5rem !important;
    }

    &.violet {
      //border: 1px solid $main-4;
      background-color: $main-4;

      &.disabled {
        background-color: $main-4-05;
     cursor: default;
        pointer-events: none;
        &:hover {
          filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0));
        }
      }
    }

    &.green {
      background-color: $green-1;

      &.disabled {
        background-color: $green-2-05;
        cursor: not-allowed;

        &:hover {
          filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0));
        }
      }
    }

    @media screen and (max-width: 575px) {
      min-width: 100%;
      margin: 0 0 .5rem !important;
      &:last-child {
        margin: 0 0 .5rem !important;
      }
    }


  }
}