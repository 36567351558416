.main {
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  margin-top: 70px;
  position: relative;
  z-index: 2;
}
.title {
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 25px;
	line-height: 44px;
	text-align: center;
	color: #303030;
	margin-bottom: 40px;
}
.subtitle {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  max-width: 800px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 40px;
  font-weight: 400;
}
.wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}
.item {
	&Inner {
		display: flex;
		flex-direction: column;
    align-items: flex-start;
	}
	&Number {
		width: 30px;
		height: 30px;
    display: grid;
    place-items: center;
		border-radius: 50%;
		background-color: #88f61b;
		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: #ffffff;
    margin-bottom: 17px;
	}
	&Title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    max-width: 140px;
    margin-bottom: 10px;
	}
	&Wrapper {
    display: flex;
    padding: 25px 20px;
    background: rgba(136, 100, 218, 0.1);
    border-radius: 18px;
    align-items: flex-start;
    justify-content: space-between;
    width: 327px;
	}
	&Part {
    display: flex;
    align-items: center;
    flex-direction: column;
	}
	&Image {
	}
	&Line {
    margin-bottom: 10px;
	}
  &Text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    color: #000000;
    max-width: 135px;

    &Wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
	&.big {
    .itemTitle {
      max-width:240px;
    }
	}
  &.tablet {
    .itemTitle {
      max-width: 230px;
    }
  }
}
.iconWrapepr {
  align-self: center;
}

@media (max-width: 1199px) {
  .main {
    padding: 0 10px;
  }
  .iconWrapepr {
    margin-top: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15%;
    > svg {
      transform: rotate(90deg);
    }
    &:nth-of-type(4) {
      justify-content: flex-start;
      padding-left: 25%;
    }
  }  
  .wrapper {
    flex-direction: column;
    align-items: center;
    max-width: 402px;
    width: 100%;
    margin: 0 auto;
  }
  .title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .subtitle {
    font-size: 12px;
    line-height: 18px;
  }
  .item {
    width: 100%;
    &:not(:first-child) {
      margin-top: 15px;
    }
    &Inner {
      align-items: flex-start;
      flex-direction: row;
      text-align: left;
      justify-content: space-between;
    }
    &Number {
      margin-bottom: 10px;
    }
    &.big {
      > div {
        flex-direction: row-reverse;
      }
      .itemTextWrapper {
        margin-left: 24px;
      }
      img {
        transform: scale(.8);
      }
    }
    &.tablet {
      .itemInner {
        flex-direction: column;
      }
    }
    img {
      max-width: 100%;
    }
  }
}