.ServiceWorkerSelect {
  background-color: #fff;

  &__avatar {
    width: 100px !important;
    height: 100px !important;
    border: 1px solid grey;
  }

  &Item {
    margin: 20px 0;
    padding: 15px;
    display: flex;
    width: 100%;
    border-top: 1px solid rgba(0,0,0,.1);

    &__title {
      font-weight: 500;
      font-size: 1.2rem;
    }

    &__picture {
      width: 120px;
      height: 120px;
      object-fit: cover;
      margin: 0 20px 20px 0;
    }
  }
}
.worker_select__list{
  height: 200px;
  overflow-y: scroll;
}