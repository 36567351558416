@import "../../vars";
.ActKSTopHead {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  //background-color: #c6c8ca;
  > div {
    text-align: right;
    font-size: $fontSize_2;
    font-weight: 500;
  }
}