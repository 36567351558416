@import "../../../scss/colors";

.wp {

  &__nav {
    position: fixed;
    top: 50px;
    width: 260px;
    height: calc(100% - 50px);
    border-right: 1px solid lightgray;
    background-color: rgba(255, 255, 255, .5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-list {
      margin: 10px 0 !important;
      height: 60vh;
      overflow-y: auto;
    }

    @media screen and (max-width: 575px) {
      width: 100%;
      height: auto;
      position: relative;
    }

  }

  &__avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid lightgray;

    &_medium {
      width: 100px !important;
      height: 100px !important;
      margin-bottom: 10px;
    }

    &_large {
      width: 150px !important;
      height: 150px !important;
      margin-bottom: 15px;
    }
  }

  &__content {
    height: 100%;
    padding: 60px 15px 15px;
    margin-left: 260px;
    width: calc(100vw - 260px);
    min-width: 20%;
    //overflow-x: scroll;
    @media screen and (max-width: 776px) {
      padding: 60px 10px 15px 0;
    }
    @media screen and (max-width: 575px) {
      margin-left: 0;
      width: 100%;
      padding: 60px 0 0;
    }
  }

  &__line {
    min-width: 1000px;
    display: flex;
    align-items: start;
    transition: 0.5s ease-in;

    &-user {
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, .01);
      }
    }
  }

  &__count {
    background: $main-4;
    width: 30px;
    height: 30px;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__data {
    margin: 0;

    &-block {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 15px;
      margin-bottom: 15px;
    }

    &-link {
      cursor: pointer;
      color: #7F5CD4 !important;

      &:hover {
        text-decoration: underline;
      }
    }


  }

  &__textarea {
    height: auto !important;

    textarea {
      padding: 0 15px !important;
    }
  }

  &__list {
    a {
      text-decoration: none;
    }
    &-categories {
      max-height: 100px;
      overflow-y: auto;
      padding: 0 5px 0 0!important;
      margin: 0 5px 0 0!important;
      cursor: default;
    }
  }

  &_LeftBlock {
    min-width: 12%;
    font-size: .8rem;
    margin-top: 10px;

    &-leftHeadingRotate {
      position: relative;

      &.top {
        align-self: flex-end;
        border-right: 1px solid $main-4;
        border-bottom: 1px solid $main-4;
        display: flex;
        justify-content: center;
        min-height: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
      }

      &.bottom {
        align-self: flex-start;
        border-bottom: 1px solid $main-4;
        border-right: 1px solid $main-4;
        display: flex;
        justify-content: center;
        min-height: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
      }

      > div {
        width: 26px;
        //  padding: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        > div {
          transform: rotate(-90deg);
          letter-spacing: .5px;
          font-weight: 500;
        }
      }
    }

    &__block {
      padding-left: 26px;
    }

    &__head {
      background: $main-4;
      color: #fff;
      height: 57px;
      border-top-left-radius: 12px;
      //border-top-right-radius: 5px;
    }
  }

  &_RightBlock {
    padding-top: 5px;
    width: 70%;
    font-size: 0.8rem;
    display: flex;
    justify-content: flex-start;
    flex: 1 0 auto;
    margin-bottom: 0;

    &__item {
      position: relative;
      min-width: 130px;
      display: flex;
      flex-direction: column;

      &.active {
        .wp__item {
          &_head {
            &-label {
              position: absolute;
              z-index: 0;
              top: -30px;
              height: 90px;
              width: 100%;
              font-weight: 500;
              display: flex;
              justify-content: center;
              align-items: flex-start;
              color: #292929 !important;

              div {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                padding: 10px;
              }
            }
          }

          &_pay {
            display: flex;
            flex-direction: column;

            &-btn {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 1rem;
              background-color: #88F61B;
              box-shadow: 0px 10px 20px rgba(136, 246, 27, 0.2);
              border-radius: 50px;
              color: #fff;
              font-weight: normal;
              height: 36px;
              margin-top: 10px;
              cursor: pointer;

              &.paid {
                opacity: .5;
                box-shadow: 0px 10px 20px rgba(136, 246, 27, 0);
                cursor: inherit;
              }
            }

            &-sum {

            }
          }
        }
      }

      &_head {
        padding: 0 6px;
        height: 40px;
        position: relative;

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
          padding: 10px 20px;
          margin: 5px 0;
          background-color: #fff;
          box-shadow: 0px 10px 20px rgba(5, 103, 208, 0.2);
          font-size: .8rem;
          position: absolute;
          width: 90%;
          z-index: 100;

          &.reported {
            * {
              color: #fff !important;
            }

            background-color: #8864DA;
            box-shadow: 0px 20px 20px rgba(5, 103, 208, 0.2);

            &.active:after {
              content: '';
              display: block;
              position: absolute;
              width: 86%;
              height: 12px;
              background-color: #8864DA;
              clip-path: polygon(0 0, 50% 100%, 100% 0);
              bottom: -11.5px;
            }

            .reported-label {
              position: absolute;
              border-radius: 50%;
              width: 22px;
              height: 22px;
              right: -5px;
              top: -5px;

              &.waiting {
                background-color: white;
              }

              &.accepted {
                background-color: #88F61B;
              }

              &.acceptedwithchanges {
                background-color: #FFB800;
              }

              &.expired {
                background-color: #FB3C6C;
              }
            }

          }

          * {
            color: $black-font !important;
          }
        }
      }

      &_total-head {
        //padding: 0 6px;
        height: 60px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        //background-color: rgba(135,100,218,.5);
      }

    }

  }

  &__row {
    height: 64px;

    &-top {
      height: 85px;
    }

    &-bottom {
      height: 99px;

      &-left {
        height: 100px;
      }

      > span {
        width: 100%;
      }
    }

    &-head {
      height: 65px;
    }
  }

  &__btn {
    background: $main-4;
    color: #fff;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
    transition: .3s ease-in;

    &:hover {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
  }

  &__modal-gallery {
    display: flex;
    cursor: default;
    flex-wrap: wrap;

    &_close {
      opacity: 0;
      transition: .3s ease-in;
      position: absolute;
    }

    > div {
      position: relative;
      background: rgba(0, 0, 0, .01);
      padding: 5px;

      svg {
        fill: #fff;
        background: #3f51b5;
        border-radius: 2px;
        right: 0;
      }

      &:hover {
        .wp__modal-gallery_close {
          opacity: 1;
          cursor: pointer;
        }
      }
    }

    img {
      object-fit: cover;
      height: 70px;
      width: 70px;
    }
  }

  &__report-status {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #fff;
    z-index: 1;
    right: -4px;
    top: -4px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.acceptedPart {
      background: #E6AC18;
    }

    &.expired {
      background: #FB3C6C;
    }

    &.accepted {
      background: #1DE05F;
    }
  }

  &__date-empty {
    font-size: 20px;
    margin: 50px;
  }
}