@import "../../../../scss/colors";

.ConfirmOrder {

  background: #fff;
  font-size: 1rem;
  padding: 0 30px 12px;
  line-height: 100%;
  filter: drop-shadow(0 -2px 10px rgba(0, 0, 0, 0.04));
  //border-bottom-left-radius: .25rem;
  //border-bottom-right-radius: .25rem;
  border-radius: .25rem;
  @media screen and(max-width: 767px) {
    font-size: .8rem;
  }

  &_label, &_label span {
    color: $main-1-2;
  }

  &_input {
    background-color: $main-1-1;
    //height: 40px;
    border-radius: 3px;
    margin-top: 8px;
    min-height: 40px;
    padding-bottom: 8px;

    &.fixed {
      padding-top: 14px;
      padding-left: 15px;
    }

    &.phone {
      .MuiInputAdornment-positionStart {
        padding-left: 15px;
      }

      input {
        padding-left: 0;
      }
    }


    input {
      padding-left: 15px;

    }

    > div {
      width: 100%;
      height: 100%;

      label {
        top: -10px;
        left: 15px;
        color: $black-font;
      }

      > div {
        height: 100%;
        margin: 0;

      }
    }
  }

  &_total {
    font-weight: 600;

    &-row {
      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  &_btn {
    background: $green-1;
    color: #fff;
    height: 40px;
    border: 1px solid transparent;
    min-width: 200px;

    display: flex;
    border-radius: 20px !important;
    align-items: center;

    flex-grow: 0;
    justify-content: center;

    cursor: pointer;
    padding: 0 10px;

    line-height: 100%;
    text-align: center;
    transition: .2s ease-in-out;
    //font-weight: 600;

    &:hover {
      text-decoration: none;
      background: #71E200;
      filter: drop-shadow(0 10px 10px rgba(136, 246, 27, 0.2));
    }

    @media screen and (max-width: 767px) {
      min-width: 100%;
    }
  }


}
