.work {
  background-color: gray;
  color: #fff;
  position: absolute;
  cursor: grab;
  padding: 5px;

  &-access {
    position: absolute;
    background-color: rgba(142, 202, 230, 0.5);
    height: 100%;
  }
}
