.main {
  margin-top: 50px;
  padding: 0 15px;
}
.inner {
  max-width: 1070px;
  width: 100%;
  margin: 0 auto;
}
.sectionText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 50px;
}

.itemText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 15px;
}
.wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 150px;
}
.arrow {
  align-self: center;
  margin-top: 190px;
}
.arrowMobile {
  display: none;
}

.part {}
.firstPart {}

.firstPart .title {
  margin-bottom: 140px;
}
.secondPart {
  margin-top: 160px;
}
.secondPart .title {
  margin-bottom: 160px;
}

.partInner {}

.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 44px;
  text-align: left;
  color: #303030;
}
.list {
  display: flex;
  align-items: center;
  padding-left: 0;
}
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  width: 140px;
  position: relative;
}
.firstPart .list .item:not(:first-child) {
  margin-left: 20px;
}
.secondPart .list .item:not(:last-child) {
  margin-right: 20px;
}
.text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.textWrapper {
  position: absolute;
}
.textWrapperTop {
  bottom: 110px;
}
.textWrapperBottom {
  top: 110px;
}
.number {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  color: #88f61b;
  position: absolute;
  left: 25px;
}
.numberToTop {
  bottom: 10px;
}

.numberToBottom {
  top: 10px;
}
.numberMobile {
  display: none;
}

.line {}
.lineToTop {}
.lineToBottom {}

.iconWrapper {
  width: 110px;
  height: 110px;
  background: #7B53DA;
  border-radius: 18.354px;
  display: grid;
  place-content: center;
}
.button {
  margin: 0 auto;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 0 82px;
}

@media (max-width: 1199px) {
  .wrapper {
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
  }
  .list {
    flex-direction: column;
  }
  .line {
    display: none;
  }
  .textWrapper {
    position: relative;
  }
  .textWrapperTop {
    bottom: 0;
  }
  .item.toTop {
    flex-direction: column-reverse;
  }
  .textWrapperBottom {
    top: 0;
  }
  .number {
    position: static;
    left: 0;
  }
  .firstPart .list .item:not(:first-child) {
    margin-left: 0px;
    margin-top: 20px;
  }
  .secondPart .list .item:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .arrow {
    margin-top: 0px;
    margin-bottom: -115px;
  }
  .firstPart .title {
    margin-bottom: 10px;
  }
  .secondPart {
    margin-top: 0px;
  }
  .secondPart .title {
    margin-bottom: 10px;
  }
  .part {
    max-width: 250px;
  }
  .title {
    text-align: center;
    line-height: 30px;
  }
  .firstPart {
    margin-bottom: -130px;
  }
  .secondPart {
    align-self: flex-end;
  }
}

@media (max-width: 575px) {
  .sectionText {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  .part {
    max-width: none;
  }
  .firstPart {
    margin-bottom: 0;
  }
  .title {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .list {
    align-items: flex-start;
  }
  .item {
    flex-direction: row;
    width: auto;
    text-align: left;
  }
  .item.toTop {
    flex-direction: row-reverse;
  }
  .center {
    display: flex;
    align-items: center;
  }
  .number {
    display: none;
  }
  .numberMobile {
    display: block;
    font-weight: 900;
    font-size: 10px;
    color: #99DC0C
  }
  .iconWrapper {
    width: 27px;
    height: 27px;
    border-radius: 5px;
    margin: 0 16px 0 8px;
  }
  .iconWrapper > svg {
    transform: scale(.3);
  }
  .arrow {
    display: none;
  }
  .text {
    font-size: 12px;
    line-height: 18px;
  }
  .button {
    margin-top: 20px;
  }
}