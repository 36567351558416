.PersonalOrderItemAccordionWrapper {

  &_container {
    font-weight: 500;
    position: relative;
    background: #fff;
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.04);
    border-radius: .25rem;
    margin-bottom: .25rem;
    @media screen and (max-width: 575px) {
      padding: 12px;
      font-size: .8rem !important;
    }

  }

  &_toggle {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1.5rem;
    cursor: pointer;

    strong {
      color: #8764DA;
    }


    &-arrow {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .2s ease-in-out;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      svg {
        fill: #8764DA;
      }

      i {
        margin: 0;
        color: #8764DA;
        width: 1rem;
        height: 1.3rem;
      }


    }
  }

}