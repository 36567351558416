.BackBtn {
  display: flex;
  margin: 20px 0;
  cursor: pointer;
  transition: opacity .2s ease-out;
  opacity: .8;

  > a {
    display: flex;

    &:hover {
      text-decoration: none;
    }
  }

  &.white {
    * {
      color: rgba(255, 255, 255, 1);
    }

    svg {
      fill: rgba(255, 255, 255, 1);
    }
  }

  &.dark {
    * {
      color: currentColor;
    }

    svg {
      fill: currentColor;
    }
  }

  &_name {
    margin: 0 5px;
  }

  &:hover {
    opacity: 1;
  }
}