@import "../../../scss/colors";

.BlueLineResult {
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  //margin: 10px 0 0;
  //color: $main-5;

  div {
    font-weight: bold;
    padding: 0 15px 0 0;
  }

  div, span {
   color: #fff;
  }

  @media screen and (max-width: 575px) {
    flex-wrap: wrap;
    font-size: 1rem;
  }
}