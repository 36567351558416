.estimate {
  &-work-kind {
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.active {
        color: #8764da;
      }
    }
    &-text {
      font-size: 1.2em;
      transition: color .3s;
      font-weight: bold;
    }
    &-image {
      width: 150px;
      height: 150px;
      object-fit: cover;
      object-position: left;
      box-shadow: 0 13px 12px -7px rgba(0, 54, 112, 0.16);
      border-radius: 20px;
    }
  }
}