.catalog-card {
  font-size: .9rem;
  overflow: hidden;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
  0px 1px 1px 0px rgba(0, 0, 0, 0.14),
  0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;

  &:hover {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 3px rgba(0, 0, 0, 0.12);
  }

  &__header {
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: space-between;
    padding: .5rem 1rem 0;
  }

  &__picture {
    width: 100%;
    height: 140px;
    object-fit: contain;
    @media all and (max-width: 992px) {
      height: 120px;
    }
  }

  &__price {
    font-size: 1.2rem;
    line-height: 140%;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  &__title {
    height: 20px;
    font-weight: 600;
    font-size: 1rem;
    padding: 0 1rem;
    line-height: 140%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media all and (max-width: 992px) {
      font-size: .9rem;
    }
  }

  &__text {
    height: 50px;
    padding: 0 1rem;
    overflow: hidden;
    //white-space: nowrap;
    //text-overflow: ellipsis;
    @media all and (max-width: 992px) {
      font-size: .8rem;
    }
  }

  a {
    text-decoration: none;
    color: currentColor;
  }

  &__footer {
    padding: .5rem 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  hr {
    margin: .5rem 0;
  }

}