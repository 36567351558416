.DraggableScroll {
  overflow: hidden;

  &_container {
    position: relative;
    //overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
    cursor: grab;
    z-index: 1;

    &.active {
      cursor: grabbing;
    }
  }

  &_arrow {
    width: 20px;
    height: 20px;
    background-color: transparent;
    position: absolute;
    z-index: 2;
    bottom: 15px;
    //cursor: grab;


    &-left {
      &:hover{
        z-index: -1;
        background-color: yellowgreen;
        display: none;
      }

    }

    &-right {
      right: 24px;
    }
  }
}