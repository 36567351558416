@import "../../scss/colors";

.PersonalTabs {
  display: flex;
  padding: 0;
  margin: 0;

  li {
    margin-right: 5%;
  }

  li a {
    color: $main-4;
    font-weight: 600;
    font-size: 1.4rem;
    opacity: .5;
    cursor: pointer;
    transition: opacity .3s ease-out;

    &:hover {
      text-decoration: none;
    }
  }

  a.ActiveTab {
    opacity: 1;
  }
}

