.Dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.6);
  z-index: 100;
  &_head {
    background-color: #D1C7F1;
    color: #fff;
    font-weight: bold;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    svg{
      position: absolute;
      right: 0;
      fill: #8764da;
      width: 18px;
      height: 18px;
      cursor: pointer;
      transition: .3s ease-in-out;

      &:hover {
        fill: #fff;
      }
    }
    &.active{
      background-color: #8764DA;
      svg{
        fill: #fff;

        &:hover {
          fill: #D1C7F1;
        }
      }
    }
  }

  &_body {
    min-height: 60vh;

    &__left {
      display: flex;
      padding: 0;
      background-color: #fff;
    }

    &__right {
      padding: 0;
    }
  }

}