.WorkersSlider {
  max-width: 1200px;
  width: 100%;
  margin: 70px auto 0;

  &__title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 38px;
    margin-bottom: 50px;
    text-align: center;
    
    @media (max-width: 575px) {
      margin-bottom: 15px;
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;

    &.next {
      right: 10%;
    }
    &.prev {
      left: 10%;
    }
  }

  &__item {
    perspective: 1000px;
    padding: 20px 0;

    &-inner {
      transition: all .7s;
      height: 250px;
      background-color: blueviolet;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      text-align: center;
      color: #000;
      font-size: 12px;
      border: 2px solid #fff;
      position: relative;

      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      &-text {
        background-color: rgba(255, 255, 255, .7);
        padding: 5px 10px 8px;
        width: 100%;
      }
    }
    &-name {
      margin-bottom: 5px;
    }
    &-num {
      width: 22px;
      height: 22px;
      background-color: #71E200;
      color: #fff;
      border-radius: 5px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 0 2px;
      font-weight: bold;
    }
  }
  @media (min-width: 992px) {
    .slick-slide {
      transition: opacity 1s;
    }
    .slick-slide:not(.slick-active) {
      transform: none !important;
      opacity: 0;
    }

    .slick-active .WorkersSlider__item-inner{
      transform: rotateY(-80deg) translateZ(-80px);
      z-index: 2;
    }
    .slick-center + .slick-active .WorkersSlider__item-inner {
      transform: rotateY(80deg) translateZ(-80px);
      z-index: 1;
    }
    .slick-center .WorkersSlider__item-inner {
      transform: rotateY(0) translateZ(100px) !important;
      z-index: 3;
    }
    .slick-center {
      position: relative;
      z-index: 2;
    }
  }
  @media (min-width: 1200px) {
    .slick-slide {
      transition: opacity 1s;
    }
    .slick-slide:not(.slick-active) {
      transform: none !important;
      opacity: 0;
    }
    .slick-active .WorkersSlider__item-inner{
      transform: rotateY(-70deg) translateZ(-170px) scale(.8);
      z-index: 1;
    }
    .slick-active + .slick-active .WorkersSlider__item-inner{
      transform: rotateY(-60deg) translateZ(-40px);
      z-index: 2;
    }
    .slick-center + .slick-active .WorkersSlider__item-inner {
      transform: rotateY(60deg) translateZ(-40px);
      z-index: 1;
    }
    .slick-center + .slick-active + .slick-active .WorkersSlider__item-inner {
      transform: rotateY(70deg) translateZ(-170px) scale(.8);
      z-index: 2;
    }
    .slick-center .WorkersSlider__item-inner {
      transform: rotateY(0) translateZ(100px) !important;
      z-index: 3;
    }
    .slick-center {
      position: relative;
      z-index: 2;
    }
    .WorkersSlider__item-name {
      display: inline-block;
    }
  }
  @media (max-width: 991px) {
    .WorkersSlider {

      &__arrow {
        top: auto;
        bottom: -13px;
        transform: translateY(0);

        &.next {
          right: auto;
          left: 55%;
        }
        &.prev {
          left: auto;
          right: 55%;
        }
      }
      
      &__item {
        &-inner {
          font-size: 14px;
          &-text {
            padding: 10px;
          }
        }
        &-name{
          display: inline-block;
          margin-bottom: 0;
          margin-right: 10px;
        }
      }
    }
  }
}