.complex {
  &-wrapper {
    min-height: 412px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-body {
    padding: 0 68px;
    flex-grow: 1;
    @media (max-width: 991px) {
      padding: 0 9px;
    }
  }
  &-head {
    &-item {
      background:#5D20C1;
      color: #fff;
      transition: all .3s;
      cursor: pointer;
      border-radius: 11px 11px 0 0;
      font-weight: 500;
      border: 1px solid transparent;

      &.active {
        background:#fff;
        color: #5D20C1;
      }

      &.last {
        border-top-right-radius: 0;
      }
      &.first {
        border-top-left-radius: 0;
      }
      
      @media (max-width: 575px) {
        &,
        &.last,
        &.first {
          border-radius: 20px;
          margin-top: 5px;
        }
        &.active {
          border-color: #5D20C1;
        }
      }
    }
  }
}
