section {
  margin-top: 70px;
}

.title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 22px;
  margin-bottom: 50px;
  text-align: center;
}
.inner {}
.list {
  padding: 0;
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
}

.item:nth-child(n+5) {
  margin-top: 25px;  
}

.itemTop {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.index {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid #7B53DA;
  display: grid;
  place-content: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  color: #7B53DA;
  margin-right: 12px;
}

.iconWrapper {
  margin-right: 30px;
}

.textWrapper {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 991px) {
  .item:nth-child(n+5) {
    margin-top: 0px;
  }
  .item:nth-child(n+3) {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .item:nth-child(n+3) {
    margin-top: 0px;
  }
  .item:nth-child(n+2) {
    margin-top: 30px;
  }
}

@media (max-width: 575px) {
  section {
    margin-top: 20px;
  }
  .title {
    margin-bottom: 15px;
    font-size: 16px;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .iconWrapper {
    width: 24px;
  }
  .iconWrapper svg {
    transform: scale(.8)
  }
  .index {
    display: none;
  }
  .textWrapper {
    font-size: 12px;
    line-height: 18px;
  }
  .item:nth-child(n+2) {
    margin-top: 13px;
  }
}