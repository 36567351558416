.estimate-hidden-input {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
}

.first-type {
  .estimate-hidden-input {
    & + .fake-input {
      border: 1px solid #8594a2;
      border-radius: 20px;
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      display: inline-block;
      position: relative;
      transition: 0.25s ease-in;
      margin-right: 15px;

      &:after {
        width: 14px;
        height: 14px;
        display: block;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        transition: 0.25s ease-in;
        content: "";
        position: absolute;
        border-radius: 50%;
      }

      & + .estimate-radio-input-text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #8594a2;
        transition: .25s ease-in;
      }
    }

    &:checked + .fake-input {
      border-color: #7b53da;

      &:after {
        background: #7b53da;
      }

      & + .estimate-radio-input-text {
        color: #000;
      }
    }

    &:focus + .fake-input {
      box-shadow: 0px 0px 3px 3px rgba(123, 83, 218, 0.5);
    }
  }
  .estimate-radio-input-sublabel {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #8594a2;
    width: 100%;
  }

  label:not(:last-child) {
    margin-bottom: 20px;
  }
}

.second-type {
  .radio-input-title {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #8594a2;

    &.small {
      font-size: 10px;
    }
  }
  .estimate-radio-inputs-wrapper {
    display: flex;
    align-items: center;
    margin-top: 6px;

    @media (max-width: 575px) {
      flex-wrap: wrap;
      justify-content: space-between;

      .radio-input-content {
        min-width: 130px;
        width: 130px;
      }
    }
  }
  .estimate-hidden-input {
    & + .radio-input-content {
      border: 1px solid #c9d6e1;
      border-radius: 20px;
      padding: 5px 16px;
      margin-right: 0;
      cursor: pointer;
      transition: 0.25s ease-in;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      color: #8594a2;
      font-style: normal;
      font-weight: 400;
      text-align: center;

      @media (max-width: 1360px) {
        font-size: 12px;
        padding: 5px 9px;
        white-space: pre;
      }
    }

    &:checked + .radio-input-content {
      border-color: transparent;
      color: #fff;
      background: #7b53da;
    }

    &:focus + .radio-input-content {
      box-shadow: 0px 0px 3px 3px rgba(123, 83, 218, 0.5);
    }
  }

  label:not(:last-child) {
    margin-right: 8px;

    @media (max-width: 575px) {
      margin-right: 0;
    }
  }
}

.estimate-radio-input-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: auto;

  label {
    display: flex;
    align-items: center;
  }
}

.label-pointer {
  cursor: pointer;
}

.estimate-button {
  height: 58px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #fff;
  background-color: #88f61b;
  gap: 15px;

  &:disabled {
    background: #dee7ef;
  }
  &.back {
    background: #fff;
    border: 1px solid #c9d6e1;
    color: #8594a2;
  }
  @media (max-width: 450px) {
    font-size: 12px;
  }
}

.estimate-switch {
  &-wrapper {
    label {
      color: #8594a2;
      transition: color 0.25s;
      &.active {
        color: #000;
      }
    }
  }
  &-title {
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: #8594a2;
  }
  &-content {
    display: flex;
  }
  &-indicator {
    background: #7b53da;
    border: 1px solid #7b53da;
    box-sizing: border-box;
    border-radius: 60px;
    width: 37px;
    height: 20px;
    margin: 0 6px;
    padding: 0 2px;
    cursor: pointer;
    position: relative;

    &-slider {
      background: #fff;
      border-radius: 60px;
      width: 16px;
      height: 16px;
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);
      left: 2px;
      transition: all 0.25s;

      &.right {
        left: 17px;
      }
    }
  }
}
.estimate-checkbox {
  display: flex;
  &-wrapper {
    .fake-input {
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
      border: 2px solid #6d7071;
      border-radius: 3px;
      display: grid;
      place-items: center;
      transition: all 0.25s;
      margin-right: 10px;

      @media (max-width: 575px) {
        &:not(:first-child) {
          margin-top: 5px;
        }
      }
    }
    input {
      &:checked + .fake-input {
        background-color: #7b53da;
      }
      &:checked:disabled + .fake-input {
        background-color: rgba(146, 146, 146, 0.27);
      }
      &:disabled + .fake-input {
        border-color: rgba(146, 146, 146, 0.27);
      }
      &:focus + .fake-input {
        box-shadow: 0px 0px 3px 3px rgba(123, 83, 218, 0.5);
        border-color: #7b53da;
      }
    }

    .form-check-label {
      display: flex;
      align-items: center;
    }
  }
}
