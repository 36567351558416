.works {

  &-wrapper {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px;
    z-index: 1;
  }
}
