.AdminGoodsDetail {
  &__thumbs {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    margin: 15px 0;
    img{
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
  }
  &__thumb{
    position: relative;
    background: #fff;
    display: flex;
    justify-content: center;align-items: center;
    height: 120px;
    margin: 5px;
  }

  &__dropzone {
    height: 100px;
    margin: 15px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 2px dashed grey;
    border-radius: 10px;
    text-align: center;
    transition: .3s ease-out;
    cursor: pointer;
    &.active{
    border: 2px dashed #3F51B5;
      opacity: .5;
    }
  }
  &__delete{
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}