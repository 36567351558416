@import "../../../../../../scss/colors";
.counter {
  display: flex;
  height: 40px;
  //padding: 0 0.75rem !important;

  & > div {
    height: 100%;
  }
}

.btn {
  width: 26px;
  cursor: pointer;
  font-size: 1rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color .2s ease-in;
  background-color: $main-1-1;
  &:hover {
    background-color: $main-4;
    svg {
      * {
        fill: #fff !important;
      }
    }
  }
}

.input {
  height: 100%;

  & > input {
    width: 60px;
    height: 100%;
    border: none;
    text-align: center;
    background-color: $main-1-1;
  }
}