@import "../../../../scss/colors";
.AdminUI {
  &_chip {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    box-shadow: 1px 1px 10px rgba(255, 255, 255, .2);
    margin-right: .5rem;

    &-active {
      background: #56ab2f; /* fallback for old browsers */
      background: -webkit-linear-gradient(to left, #a8e063, #56ab2f); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to left, #a8e063, #56ab2f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


    }

    &-disable {
      background: #FF416C; /* fallback for old browsers */
      background: -webkit-linear-gradient(to left, #FF4B2B, #FF416C); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to left, #FF4B2B, #FF416C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


    }

    &-edited {
      background: #F2994A; /* fallback for old browsers */
      background: -webkit-linear-gradient(to left, #F2C94C, #F2994A); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to left, #F2C94C, #F2994A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


    }

  }

  &_modal {
    min-width: 320px;
    text-align: center;
    padding: 1rem;

  }

  &_loader-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
  }

  &_loader {
    display: inline-block;
    position: relative;

    svg {
      margin: auto;
      display: block;
      shape-rendering: auto;
      width: 164px;
      height: 164px;
    }
  }

  &__icon {
    &.default {
      fill: $blue;
    }
  }
}

button.Admin_btn {
  //width: 26px !important;
  height: 26px;
  max-width: 26px !important;
  min-width: 26px !important;
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    font-size: .8rem;
  }

  svg {
    width: 22px;
    height: 22px;
  }


  &.text {
    width: auto !important;
    max-width: 100% !important;
    padding: 16px 10px;
  }
}

