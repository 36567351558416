.fileInput {
  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  &__input {
    overflow: 0;
    position: absolute;
    width: 0;
    height: 0;
  }
  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    cursor: pointer;
  }
  &__button {
    width: 162px;
    height: 40px;
    background: #8764DA;
    border-radius: 50px;
    color: #fff;
    display: grid;
    place-items: center;
    font-weight: 400;
    transition: all .3s;
    margin-right: 13px;

    &:hover {
      background-color: #7042DD;
    }
  }
  &__text {
    font-size: 15px;
    color: #8764DA;
  }
  &__cross {
    cursor: pointer;
    margin-left: 5px;
    padding: 0.1rem;
  }
  &__error {
    width: 100%;
    font-size: 12px;
    color: red
  }
}

.textarea {
  padding-left: 15px;
}