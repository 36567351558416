$width_1: 15%;
$width_2: 65%;
$width_3: 8%;
$width_4: 12%;

$width_5: 20%;

$height_1: 1.1rem;
$height_2: 1.5rem;
$height_3: 2.2rem;
$height_4: 2.4rem;
$height_5: 3.7rem;
$height_6: 1.65rem;
$height_7: 3.3rem;


$color: black;
$colorBlue: #47009E;

$fontSize_1: .7rem;
$fontSize_2: .8rem;
$fontSize_3: .9rem;


//$actWidth: 1189px;
//$actWidth: 297mm;
$actWidth: 287mm;
//$actHeight: 841px;
//$actHeight: 210mm;
$actHeight: 200mm;
$actPadding: 30px 30px 30px 40px;

