@import "../../../../../scss/colors";
.PersonalOrderItemPayments {
  &_head {
    display: flex;
    justify-content: space-between;
    //background-color: #c6c8ca;
    border-bottom: 1px solid #D7DAED;

    &.materials {
      > div:first-child {
        width: 55%;
      }
    }

    &.services {
      > div:first-child {
        width: 25%;
      }
    }

    //p {
    //  flex: 1 0 auto;
    //  font-size: 1rem;
    //  //color: #D7DAED;
    //  color: #000;
    //  width: 15%;
    //  margin: 0 0 5px;
    //  align-self: flex-end;
    //}
  }

  &_body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;


    &.materials {
      > div:first-child {
        width: 55%;
      }
    }

    &.services {
      > div:first-child {
        width: 25%;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #D7DAED;
      }
    }

    div {
      //flex: 1 0 auto;
      //font-size: .8rem;
      //width: 15%;
    }

  }

  &_pay-btn {
    background-color: #88F61B;
    border-radius: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    width: fit-content;

    &.paid {
      opacity: .5;
      cursor: default;
    }

    &.disabled {
      background-color: $gray;
      cursor: default;
    }
  }

  &__paid{
    position: relative;
    //transform: translateY(-20px);
    font-size: 1rem;
    color: #88F61B;
  }
}