@import "../../../scss/colors";

.TooltipBtn {
  //position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  //margin-left: 15px;
  background-color: #A5A5A5;
  transition: .3s ease-out;
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);


  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);

    & * {
      fill: #fff;
    }
  }

  &.active {
    background-color: #fff;

    svg {
      & * {
        fill: $main-4;
      }
    }
  }
}