.ApiDocs {
  min-height: 100vh;

  .logo {
    //padding: 20px;
    img {
      width: 106px;
      height: 106px;
      object-fit: cover;
      filter: grayscale(.5);
      border-radius: 50%;
      border: 3px solid #444444;
    }
  }

  &_content {
    min-height: calc(100vh - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_bg-logo {
    width: 600px;
    height: 600px;
    object-fit: cover;
    margin: 0 auto;
    border-radius: 10px;
    filter: grayscale(1) opacity(.7);
    @media screen and (max-width: 575px) {
      width: 300px;
      height: 300px;
    }
  }

  p {
    font-size: 1.1rem;
  }

  &_copy {
    margin-left: 10px;
    cursor: pointer;
    transition: 1s ease-in-out;

    &:hover {
      opacity: .7;

    }
  }

  &_title {
    font-size: 1.2rem;
  }

  &_title-2 {
    font-size: 1.1rem;
  }

  &_tab-btn {

    button {
      width: 150px;
    }
  }

  .card-body {
    padding: 5px 5px 0 5px !important;

    &:last-child {
      padding: 5px 5px 5px 5px !important;
    }
  }

  .card-header {
    cursor: pointer;
  }

  &_link {
    cursor: pointer;
    padding: 10px 10px 10px 15px;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 2px;
    transition: .2s ease-in-out;
    display: flex;
    justify-content: space-between;

    &:hover {
      background-color: #444;
    }
  }

  &_controls {
    width: fit-content;
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }

  &_left {
    position: relative;
    left: -100%;
    animation: move_from_left .3s ease-in-out forwards;

    p {
      font-size: 1.2rem;
      letter-spacing: .05rem;
      font-weight: 400;
    }
  }

  @keyframes move_from_left {
    0% {
      left: -100%;
    }
    100% {
      left: 0;
    }
  }

  &_right {
    position: relative;
    right: -100%;
    animation: move_from_right .3s ease-in-out forwards;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @keyframes move_from_right {
    0% {
      right: -100%;
    }
    100% {
      right: 0;
    }
  }

  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: left;
  background-color: #222222;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);


  .btn-success:hover {
    color: #fff;
    background-color: #009670;
    border-color: #008966
  }

  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #009670;
    border-color: #008966;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 198, 157, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(38, 198, 157, 0.5)
  }

  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #00bc8c;
    border-color: #00bc8c
  }

  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #008966;
    border-color: #007c5d
  }

  .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 198, 157, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(38, 198, 157, 0.5)
  }

  .btn-info {
    color: #fff;
    background-color: #3498DB;
    border-color: #3498DB
  }

  .btn-info:hover {
    color: #fff;
    background-color: #2384c6;
    border-color: #217dbb
  }

  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #2384c6;
    border-color: #217dbb;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 167, 224, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(82, 167, 224, 0.5)
  }

  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #3498DB;
    border-color: #3498DB
  }

  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #217dbb;
    border-color: #1f76b0
  }

  .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 167, 224, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(82, 167, 224, 0.5)
  }

  .btn-warning {
    color: #fff;
    background-color: #F39C12;
    border-color: #F39C12
  }

  .btn-warning:hover {
    color: #fff;
    background-color: #d4860b;
    border-color: #c87f0a
  }

  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #d4860b;
    border-color: #c87f0a;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(245, 171, 54, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(245, 171, 54, 0.5)
  }

  .btn-warning.disabled, .btn-warning:disabled {
    color: #fff;
    background-color: #F39C12;
    border-color: #F39C12
  }

  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #c87f0a;
    border-color: #bc770a
  }

  .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(245, 171, 54, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(245, 171, 54, 0.5)
  }

  .btn-danger {
    color: #fff;
    background-color: #E74C3C;
    border-color: #E74C3C
  }

  .btn-danger:hover {
    color: #fff;
    background-color: #e12e1c;
    border-color: #d62c1a
  }

  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #e12e1c;
    border-color: #d62c1a;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(235, 103, 89, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(235, 103, 89, 0.5)
  }

  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #E74C3C;
    border-color: #E74C3C
  }

  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #d62c1a;
    border-color: #ca2a19
  }

  .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(235, 103, 89, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(235, 103, 89, 0.5)
  }

  .btn-light {
    color: #222;
    background-color: #adb5bd;
    border-color: #adb5bd
  }

  .btn-light:hover {
    color: #fff;
    background-color: #98a2ac;
    border-color: #919ca6
  }

  .btn-light:focus, .btn-light.focus {
    color: #fff;
    background-color: #98a2ac;
    border-color: #919ca6;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(152, 159, 166, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(152, 159, 166, 0.5)
  }

  .btn-light.disabled, .btn-light:disabled {
    color: #222;
    background-color: #adb5bd;
    border-color: #adb5bd
  }

  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
    color: #fff;
    background-color: #919ca6;
    border-color: #8a95a1
  }

  .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(152, 159, 166, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(152, 159, 166, 0.5)
  }

  .btn-dark {
    color: #fff;
    background-color: #303030;
    border-color: #303030
  }

  .btn-dark:hover {
    color: #fff;
    background-color: #1d1d1d;
    border-color: #171616
  }

  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #1d1d1d;
    border-color: #171616;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(79, 79, 79, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(79, 79, 79, 0.5)
  }

  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #303030;
    border-color: #303030
  }

  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #171616;
    border-color: #101010
  }

  .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(79, 79, 79, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(79, 79, 79, 0.5)
  }

  .btn-outline-primary {
    color: #375a7f;
    border-color: #375a7f
  }

  .btn-outline-primary:hover {
    color: #fff;
    background-color: #375a7f;
    border-color: #375a7f
  }

  .btn-outline-primary:focus, .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(55, 90, 127, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(55, 90, 127, 0.5)
  }

  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #375a7f;
    background-color: transparent
  }

  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #375a7f;
    border-color: #375a7f
  }

  .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(55, 90, 127, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(55, 90, 127, 0.5)
  }

  .btn-outline-secondary {
    color: #444;
    border-color: #444
  }

  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #444;
    border-color: #444
  }

  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(68, 68, 68, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(68, 68, 68, 0.5)
  }

  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #444;
    background-color: transparent
  }

  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #444;
    border-color: #444
  }

  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(68, 68, 68, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(68, 68, 68, 0.5)
  }

  .btn-outline-success {
    color: #00bc8c;
    border-color: #00bc8c
  }

  .btn-outline-success:hover {
    color: #fff;
    background-color: #00bc8c;
    border-color: #00bc8c
  }

  .btn-outline-success:focus, .btn-outline-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 188, 140, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 140, 0.5)
  }

  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #00bc8c;
    background-color: transparent
  }

  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #00bc8c;
    border-color: #00bc8c
  }

  .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 188, 140, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 140, 0.5)
  }

  .btn-outline-info {
    color: #3498DB;
    border-color: #3498DB
  }

  .btn-outline-info:hover {
    color: #fff;
    background-color: #3498DB;
    border-color: #3498DB
  }

  .btn-outline-info:focus, .btn-outline-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.5)
  }

  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #3498DB;
    background-color: transparent
  }

  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #3498DB;
    border-color: #3498DB
  }

  .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.5)
  }

  .btn-outline-warning {
    color: #F39C12;
    border-color: #F39C12
  }

  .btn-outline-warning:hover {
    color: #fff;
    background-color: #F39C12;
    border-color: #F39C12
  }

  .btn-outline-warning:focus, .btn-outline-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(243, 156, 18, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(243, 156, 18, 0.5)
  }

  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #F39C12;
    background-color: transparent
  }

  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #F39C12;
    border-color: #F39C12
  }

  .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(243, 156, 18, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(243, 156, 18, 0.5)
  }

  .btn-outline-danger {
    color: #E74C3C;
    border-color: #E74C3C
  }

  .btn-outline-danger:hover {
    color: #fff;
    background-color: #E74C3C;
    border-color: #E74C3C
  }

  .btn-outline-danger:focus, .btn-outline-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.5)
  }

  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #E74C3C;
    background-color: transparent
  }

  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #E74C3C;
    border-color: #E74C3C
  }

  .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.5)
  }

  .btn-outline-light {
    color: #adb5bd;
    border-color: #adb5bd
  }

  .btn-outline-light:hover {
    color: #222;
    background-color: #adb5bd;
    border-color: #adb5bd
  }

  .btn-outline-light:focus, .btn-outline-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5)
  }

  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #adb5bd;
    background-color: transparent
  }

  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
    color: #222;
    background-color: #adb5bd;
    border-color: #adb5bd
  }

  .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5)
  }

  .btn-outline-dark {
    color: #303030;
    border-color: #303030
  }

  .btn-outline-dark:hover {
    color: #fff;
    background-color: #303030;
    border-color: #303030
  }

  .btn-outline-dark:focus, .btn-outline-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(48, 48, 48, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(48, 48, 48, 0.5)
  }

  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #303030;
    background-color: transparent
  }

  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #303030;
    border-color: #303030
  }

  .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(48, 48, 48, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(48, 48, 48, 0.5)
  }

  .btn-link {
    font-weight: 400;
    color: #00bc8c;
    text-decoration: none
  }

  .btn-link:hover {
    color: #007053;
    text-decoration: underline
  }

  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    -webkit-box-shadow: none;
    box-shadow: none
  }

  .btn-link:disabled, .btn-link.disabled {
    color: #888;
    pointer-events: none
  }

  .btn-lg, .btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.171875rem;
    line-height: 1.5;
    border-radius: 0.3rem
  }

  .btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.8203125rem;
    line-height: 1.5;
    border-radius: 0.2rem
  }

  .btn-block {
    display: block;
    width: 100%
  }

  .btn-block + .btn-block {
    margin-top: 0.5rem
  }

  input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
    width: 100%
  }

  .fade {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear
  }

  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0
  }

  .custom-file-input:focus ~ .custom-file-label {
    border-color: #739ac2;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(55, 90, 127, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(55, 90, 127, 0.25)
  }

  .custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
    background-color: #ebebeb
  }

  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"
  }

  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse)
  }

  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #adb5bd;
    background-color: #fff;
    border: 1px solid #222;
    border-radius: 0.25rem
  }

  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #adb5bd;
    content: "Browse";
    background-color: #444;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0
  }

  .custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
  }

  .custom-range:focus {
    outline: none
  }

  .custom-range:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 1px #222, 0 0 0 0.2rem rgba(55, 90, 127, 0.25);
    box-shadow: 0 0 0 1px #222, 0 0 0 0.2rem rgba(55, 90, 127, 0.25)
  }

  .custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #222, 0 0 0 0.2rem rgba(55, 90, 127, 0.25)
  }

  .custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #222, 0 0 0 0.2rem rgba(55, 90, 127, 0.25)
  }

  .custom-range::-moz-focus-outer {
    border: 0
  }

  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #375a7f;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
  }

  @media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
      -webkit-transition: none;
      transition: none
    }
  }

  .custom-range::-webkit-slider-thumb:active {
    background-color: #97b3d2
  }

  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem
  }

  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #375a7f;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none
  }

  @media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
      -webkit-transition: none;
      transition: none
    }
  }

  .custom-range::-moz-range-thumb:active {
    background-color: #97b3d2
  }

  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem
  }

  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #375a7f;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    appearance: none
  }

  .custom-range::-ms-thumb:active {
    background-color: #97b3d2
  }

  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem
  }

  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem
  }

  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem
  }

  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd
  }

  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default
  }

  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd
  }

  .custom-range:disabled::-moz-range-track {
    cursor: default
  }

  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd
  }

  .custom-control-label::before, .custom-file-label, .custom-select {
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out
  }

  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before, .custom-file-label, .custom-select {
      -webkit-transition: none;
      transition: none
    }
  }

  .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
  }

  .nav-link {
    display: block;
    padding: 0.5rem 2rem
  }

  .nav-link:hover, .nav-link:focus {
    text-decoration: none
  }

  .nav-link.disabled {
    color: #adb5bd;
    pointer-events: none;
    cursor: default
  }

  .nav-tabs {
    border-bottom: 1px solid #444
  }

  .nav-tabs .nav-item {
    margin-bottom: -1px
  }

  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #444 #444 transparent
  }

  .nav-tabs .nav-link.disabled {
    color: #adb5bd;
    background-color: transparent;
    border-color: transparent
  }

  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #fff;
    background-color: #222;
    border-color: #444 #444 transparent
  }

  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .nav-pills .nav-link {
    border-radius: 0.25rem
  }

  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #375a7f
  }

  .nav-fill .nav-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center
  }

  .nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center
  }

  .tab-content > .tab-pane {
    display: none
  }

  .tab-content > .active {
    display: block
  }

  .navbar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem
  }

  .navbar .container, .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .navbar-brand {
    display: inline-block;
    padding-top: 0.32421875rem;
    padding-bottom: 0.32421875rem;
    margin-right: 1rem;
    font-size: 1.171875rem;
    line-height: inherit;
    white-space: nowrap
  }

  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none
  }

  .navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
  }

  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
  }

  .navbar-nav .dropdown-menu {
    position: static;
    float: none
  }

  .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.171875rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem
  }

  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%
  }

  @media (max-width: 575.98px) {
    .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      padding-right: 0;
      padding-left: 0
    }
  }

  @media (min-width: 576px) {
    .navbar-expand-sm {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem
    }

    .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap
    }

    .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
      display: none
    }
  }

  @media (max-width: 767.98px) {
    .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      padding-right: 0;
      padding-left: 0
    }
  }

  @media (min-width: 768px) {
    .navbar-expand-md {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem
    }

    .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap
    }

    .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
      display: none
    }
  }

  @media (max-width: 991.98px) {
    .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      padding-right: 0;
      padding-left: 0
    }
  }

  @media (min-width: 992px) {
    .navbar-expand-lg {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem
    }

    .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap
    }

    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
      display: none
    }
  }

  @media (max-width: 1199.98px) {
    .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      padding-right: 0;
      padding-left: 0
    }
  }

  @media (min-width: 1200px) {
    .navbar-expand-xl {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem
    }

    .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap
    }

    .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
      display: none
    }
  }

  .navbar-expand {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0
  }

  .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
  }

  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem
  }

  .navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
  }

  .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
  }

  .navbar-expand .navbar-toggler {
    display: none
  }

  .navbar-light .navbar-brand {
    color: #222
  }

  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: #222
  }

  .navbar-light .navbar-nav .nav-link {
    color: rgba(34, 34, 34, 0.7)
  }

  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #222
  }

  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3)
  }

  .navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
    color: #222
  }

  .navbar-light .navbar-toggler {
    color: rgba(34, 34, 34, 0.7);
    border-color: rgba(34, 34, 34, 0.1)
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(34, 34, 34, 0.7)' strokeLinecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
  }

  .navbar-light .navbar-text {
    color: rgba(34, 34, 34, 0.7)
  }

  .navbar-light .navbar-text a {
    color: #222
  }

  .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
    color: #222
  }

  .navbar-dark .navbar-brand {
    color: #fff
  }

  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff
  }

  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.6)
  }

  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #fff
  }

  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25)
  }

  .navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
    color: #fff
  }

  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.6);
    border-color: rgba(255, 255, 255, 0.1)
  }

  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.6)' strokeLinecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
  }

  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.6)
  }

  .navbar-dark .navbar-text a {
    color: #fff
  }

  .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
    color: #fff
  }

  .card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #303030;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem
  }

  .card > hr {
    margin-right: 0;
    margin-left: 0
  }

  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem
  }

  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem
  }

  .card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem
  }

  .card-title {
    margin-bottom: 0.75rem
  }

  .card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0
  }

  .card-text:last-child {
    margin-bottom: 0
  }

  .card-link:hover {
    text-decoration: none
  }

  .card-link + .card-link {
    margin-left: 1.25rem
  }

  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #444;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125)
  }

  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0
  }

  .card-header + .list-group .list-group-item:first-child {
    border-top: 0
  }

  .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: #444;
    border-top: 1px solid rgba(0, 0, 0, 0.125)
  }

  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px)
  }

  .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0
  }

  .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem
  }

  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem
  }

  .card-img, .card-img-top, .card-img-bottom {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%
  }

  .card-img, .card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px)
  }

  .card-img, .card-img-bottom {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px)
  }

  .card-deck .card {
    margin-bottom: 15px
  }

  @media (min-width: 576px) {
    .card-deck {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px
    }

    .card-deck .card {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px
    }
  }

  .card-group > .card {
    margin-bottom: 15px
  }

  @media (min-width: 576px) {
    .card-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap
    }

    .card-group > .card {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-bottom: 0
    }

    .card-group > .card + .card {
      margin-left: 0;
      border-left: 0
    }

    .card-group > .card:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0
    }

    .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
      border-top-right-radius: 0
    }

    .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
      border-bottom-right-radius: 0
    }

    .card-group > .card:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0
    }

    .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
      border-top-left-radius: 0
    }

    .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
      border-bottom-left-radius: 0
    }
  }

  .card-columns .card {
    margin-bottom: 0.75rem
  }


  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      transition: none
    }
  }

  a.badge:hover, a.badge:focus {
    text-decoration: none
  }

  .badge:empty {
    display: none
  }

  .btn .badge {
    position: relative;
    top: -1px
  }

  .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem
  }

  .badge-primary {
    color: #fff;
    background-color: #375a7f
  }

  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #28415b
  }

  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(55, 90, 127, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(55, 90, 127, 0.5)
  }

  .badge-secondary {
    color: #fff;
    background-color: #444
  }

  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #2b2a2a
  }

  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(68, 68, 68, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(68, 68, 68, 0.5)
  }

  .badge-success {
    color: #fff;
    background-color: #00bc8c
  }

  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #008966
  }

  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 188, 140, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 140, 0.5)
  }

  .badge-info {
    color: #fff;
    background-color: #3498DB
  }

  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #217dbb
  }

  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 152, 219, 0.5)
  }

  .badge-warning {
    color: #fff;
    background-color: #F39C12
  }

  a.badge-warning:hover, a.badge-warning:focus {
    color: #fff;
    background-color: #c87f0a
  }

  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(243, 156, 18, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(243, 156, 18, 0.5)
  }

  .badge-danger {
    color: #fff;
    background-color: #E74C3C
  }

  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #d62c1a
  }

  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.5)
  }

  .badge-light {
    color: #222;
    background-color: #adb5bd
  }

  a.badge-light:hover, a.badge-light:focus {
    color: #222;
    background-color: #919ca6
  }

  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5)
  }

  .badge-dark {
    color: #fff;
    background-color: #303030
  }

  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #171616
  }

  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(48, 48, 48, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(48, 48, 48, 0.5)
  }

  .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #303030;
    border-radius: 0.3rem
  }

  @media (min-width: 576px) {
    .jumbotron {
      padding: 2rem
    }
  }

  .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0
  }

  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem
  }

  .alert-heading {
    color: inherit
  }

  .alert-link {
    font-weight: 700
  }

  .alert-dismissible {
    padding-right: 3.90625rem
  }

  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit
  }

  .alert-primary {
    color: #1d2f42;
    background-color: #d7dee5;
    border-color: #c7d1db
  }

  .alert-primary hr {
    border-top-color: #b7c4d1
  }

  .alert-primary .alert-link {
    color: #0d161f
  }

  .alert-secondary {
    color: #232323;
    background-color: #dadada;
    border-color: #cbcbcb
  }

  .alert-secondary hr {
    border-top-color: #bebebe
  }

  .alert-secondary .alert-link {
    color: #0a0909
  }

  .alert-success {
    color: #006249;
    background-color: #ccf2e8;
    border-color: #b8ecdf
  }

  .alert-success hr {
    border-top-color: #a4e7d6
  }

  .alert-success .alert-link {
    color: #002f23
  }

  .alert-info {
    color: #1b4f72;
    background-color: #d6eaf8;
    border-color: #c6e2f5
  }

  .alert-info hr {
    border-top-color: #b0d7f1
  }

  .alert-info .alert-link {
    color: #113249
  }

  .alert-warning {
    color: #7e5109;
    background-color: #fdebd0;
    border-color: #fce3bd
  }

  .alert-warning hr {
    border-top-color: #fbd9a5
  }

  .alert-warning .alert-link {
    color: #4e3206
  }

  .alert-danger {
    color: #78281f;
    background-color: #fadbd8;
    border-color: #f8cdc8
  }

  .alert-danger hr {
    border-top-color: #f5b8b1
  }

  .alert-danger .alert-link {
    color: #4f1a15
  }

  .alert-light {
    color: #5a5e62;
    background-color: #eff0f2;
    border-color: #e8eaed
  }

  .alert-light hr {
    border-top-color: #dadde2
  }

  .alert-light .alert-link {
    color: #424547
  }

  .alert-dark {
    color: #191919;
    background-color: #d6d6d6;
    border-color: #c5c5c5
  }

  .alert-dark hr {
    border-top-color: #b8b8b8
  }

  .alert-dark .alert-link {
    color: black
  }


  .list-group-flush .list-group-item {
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0
  }

  .list-group-flush .list-group-item:first-child {
    border-top-width: 0
  }

  .list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 0
  }

  .list-group-item-primary {
    color: #1d2f42;
    background-color: #c7d1db
  }

  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #1d2f42;
    background-color: #b7c4d1
  }

  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #1d2f42;
    border-color: #1d2f42
  }

  .list-group-item-secondary {
    color: #232323;
    background-color: #cbcbcb
  }

  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #232323;
    background-color: #bebebe
  }

  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #232323;
    border-color: #232323
  }

  .list-group-item-success {
    color: #006249;
    background-color: #b8ecdf
  }

  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #006249;
    background-color: #a4e7d6
  }

  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #006249;
    border-color: #006249
  }

  .list-group-item-info {
    color: #1b4f72;
    background-color: #c6e2f5
  }

  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #1b4f72;
    background-color: #b0d7f1
  }

  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #1b4f72;
    border-color: #1b4f72
  }

  .list-group-item-warning {
    color: #7e5109;
    background-color: #fce3bd
  }

  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #7e5109;
    background-color: #fbd9a5
  }

  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #7e5109;
    border-color: #7e5109
  }

  .list-group-item-danger {
    color: #78281f;
    background-color: #f8cdc8
  }

  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #78281f;
    background-color: #f5b8b1
  }

  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #78281f;
    border-color: #78281f
  }

  .list-group-item-light {
    color: #5a5e62;
    background-color: #e8eaed
  }

  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #5a5e62;
    background-color: #dadde2
  }

  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #5a5e62;
    border-color: #5a5e62
  }

  .list-group-item-dark {
    color: #191919;
    background-color: #c5c5c5
  }

  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #191919;
    background-color: #b8b8b8
  }

  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #191919;
    border-color: #191919
  }

  .close {
    float: right;
    font-size: 1.40625rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-shadow: none;
    opacity: .5
  }

  .close:hover {
    color: #fff;
    text-decoration: none
  }

  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75
  }

  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
  }

  a.close.disabled {
    pointer-events: none
  }

  .toast {
    max-width: 350px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: #444;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: 0.25rem
  }

  .toast:not(:last-child) {
    margin-bottom: 0.75rem
  }

  .toast.showing {
    opacity: 1
  }

  .toast.show {
    display: block;
    opacity: 1
  }

  .toast.hide {
    display: none
  }

  .toast-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #888;
    background-color: #303030;
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05)
  }

  .toast-body {
    padding: 0.75rem
  }

  .modal-open {
    overflow: hidden
  }

  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0
  }

  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none
  }

  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px)
  }

  @media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      -webkit-transition: none;
      transition: none
    }
  }

  .modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none
  }

  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    transform: scale(1.02)
  }

  .modal-dialog-scrollable {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-height: calc(100% - 1rem)
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden
  }

  .modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer {
    -ms-flex-negative: 0;
    flex-shrink: 0
  }

  .modal-dialog-scrollable .modal-body {
    overflow-y: auto
  }

  .modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem)
  }

  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""
  }

  .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%
  }

  .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none
  }

  .modal-dialog-centered.modal-dialog-scrollable::before {
    content: none
  }

  .modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #303030;
    background-clip: padding-box;
    border: 1px solid #444;
    border-radius: 0.3rem;
    outline: 0
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000
  }

  .modal-backdrop.fade {
    opacity: 0
  }

  .modal-backdrop.show {
    opacity: 0.5
  }

  .modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #444;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px)
  }

  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto
  }

  .modal-title {
    margin-bottom: 0;
    line-height: 1.5
  }

  .modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem
  }

  .modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #444;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px)
  }

  .modal-footer > * {
    margin: 0.25rem
  }

  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto
    }

    .modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem)
    }

    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem)
    }

    .modal-dialog-centered {
      min-height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem)
    }

    .modal-sm {
      max-width: 300px
    }
  }

  @media (min-width: 992px) {
    .modal-lg, .modal-xl {
      max-width: 800px
    }
  }

  @media (min-width: 1200px) {
    .modal-xl {
      max-width: 1140px
    }
  }

  .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.8203125rem;
    word-wrap: break-word;
    opacity: 0
  }

  .tooltip.show {
    opacity: 0.9
  }

  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem
  }

  .tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
  }

  .bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0
  }

  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0
  }

  .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000
  }

  .bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem
  }

  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem
  }

  .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000
  }

  .bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0
  }

  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0
  }

  .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000
  }

  .bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem
  }

  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem
  }

  .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000
  }

  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem
  }

  .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.8203125rem;
    word-wrap: break-word;
    background-color: #303030;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem
  }

  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem
  }

  .popover .arrow::before, .popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
  }

  .bs-popover-top, .bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem
  }

  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px)
  }

  .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25)
  }

  .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #303030
  }

  .bs-popover-right, .bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem
  }

  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0
  }

  .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25)
  }

  .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #303030
  }

  .bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem
  }

  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px)
  }

  .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25)
  }

  .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #303030
  }

  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #444
  }

  .bs-popover-left, .bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem
  }

  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0
  }

  .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25)
  }

  .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #303030
  }

  .popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.9375rem;
    background-color: #444;
    border-bottom: 1px solid #373737;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px)
  }

  .popover-header:empty {
    display: none
  }

  .popover-body {
    padding: 0.5rem 0.75rem;
    color: #fff
  }

  .carousel {
    position: relative
  }

  .carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y
  }

  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
  }

  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""
  }

  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out
  }

  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      transition: none
    }
  }

  .carousel-item.active, .carousel-item-next, .carousel-item-prev {
    display: block
  }

  .carousel-item-next:not(.carousel-item-left), .active.carousel-item-right {
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
  }

  .carousel-item-prev:not(.carousel-item-right), .active.carousel-item-left {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
  }

  .carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transform: none;
    transform: none
  }

  .carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1
  }

  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    -webkit-transition: opacity 0s 0.6s;
    transition: opacity 0s 0.6s
  }

  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      transition: none
    }
  }

  .carousel-control-prev, .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    -webkit-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease
  }

  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev, .carousel-control-next {
      -webkit-transition: none;
      transition: none
    }
  }

  .carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9
  }

  .carousel-control-prev {
    left: 0
  }

  .carousel-control-next {
    right: 0
  }

  .carousel-control-prev-icon, .carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50% / 100% 100%
  }

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
  }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")
  }

  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none
  }

  .carousel-indicators li {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease
  }

  @media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
      -webkit-transition: none;
      transition: none
    }
  }

  .carousel-indicators .active {
    opacity: 1
  }

  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center
  }

  @-webkit-keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
    }
  }

  @keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
    }
  }

  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite
  }

  .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em
  }

  @-webkit-keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0)
    }
    50% {
      opacity: 1
    }
  }

  @keyframes spinner-grow {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0)
    }
    50% {
      opacity: 1
    }
  }

  .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite
  }

  .spinner-grow-sm {
    width: 1rem;
    height: 1rem
  }

  .align-baseline {
    vertical-align: baseline !important
  }

  .align-top {
    vertical-align: top !important
  }

  .align-middle {
    vertical-align: middle !important
  }

  .align-bottom {
    vertical-align: bottom !important
  }

  .align-text-bottom {
    vertical-align: text-bottom !important
  }

  .align-text-top {
    vertical-align: text-top !important
  }

  .bg-primary {
    background-color: #375a7f !important
  }

  a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
    background-color: #28415b !important
  }

  .bg-secondary {
    background-color: #444 !important
  }

  a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus {
    background-color: #2b2a2a !important
  }

  .bg-success {
    background-color: #00bc8c !important
  }

  a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus {
    background-color: #008966 !important
  }

  .bg-info {
    background-color: #3498DB !important
  }

  a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus {
    background-color: #217dbb !important
  }

  .bg-warning {
    background-color: #F39C12 !important
  }

  a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus {
    background-color: #c87f0a !important
  }

  .bg-danger {
    background-color: #E74C3C !important
  }

  a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus {
    background-color: #d62c1a !important
  }

  .bg-light {
    background-color: #adb5bd !important
  }

  a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus {
    background-color: #919ca6 !important
  }

  .bg-dark {
    background-color: #303030 !important
  }

  a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus {
    background-color: #171616 !important
  }

  .bg-white {
    background-color: #fff !important
  }

  .bg-transparent {
    background-color: transparent !important
  }

  .border {
    border: 1px solid #dee2e6 !important
  }

  .border-top {
    border-top: 1px solid #dee2e6 !important
  }

  .border-right {
    border-right: 1px solid #dee2e6 !important
  }

  .border-bottom {
    border-bottom: 1px solid #dee2e6 !important
  }

  .border-left {
    border-left: 1px solid #dee2e6 !important
  }

  .border-0 {
    border: 0 !important
  }

  .border-top-0 {
    border-top: 0 !important
  }

  .border-right-0 {
    border-right: 0 !important
  }

  .border-bottom-0 {
    border-bottom: 0 !important
  }

  .border-left-0 {
    border-left: 0 !important
  }

  .border-primary {
    border-color: #375a7f !important
  }

  .border-secondary {
    border-color: #444 !important
  }

  .border-success {
    border-color: #00bc8c !important
  }

  .border-info {
    border-color: #3498DB !important
  }

  .border-warning {
    border-color: #F39C12 !important
  }

  .border-danger {
    border-color: #E74C3C !important
  }

  .border-light {
    border-color: #adb5bd !important
  }

  .border-dark {
    border-color: #303030 !important
  }

  .border-white {
    border-color: #fff !important
  }


  .shadow-sm {
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important
  }

  .shadow {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important
  }

  .shadow-lg {
    -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important
  }

  .shadow-none {
    -webkit-box-shadow: none !important;
    box-shadow: none !important
  }


  .text-white {
    color: #fff !important
  }

  .text-primary {
    color: #375a7f !important
  }

  a.text-primary:hover, a.text-primary:focus {
    color: #20344a !important
  }

  .text-secondary {
    color: #444 !important
  }

  a.text-secondary:hover, a.text-secondary:focus {
    color: #1e1e1e !important
  }

  .text-success {
    color: #00bc8c !important
  }

  a.text-success:hover, a.text-success:focus {
    color: #007053 !important
  }

  .text-info {
    color: #3498DB !important
  }

  a.text-info:hover, a.text-info:focus {
    color: #1d6fa5 !important
  }

  .text-warning {
    color: #F39C12 !important
  }

  a.text-warning:hover, a.text-warning:focus {
    color: #b06f09 !important
  }

  .text-danger {
    color: #E74C3C !important
  }

  a.text-danger:hover, a.text-danger:focus {
    color: #bf2718 !important
  }

  .text-light {
    color: #adb5bd !important
  }

  a.text-light:hover, a.text-light:focus {
    color: #838f9b !important
  }

  .text-dark {
    color: #303030 !important
  }

  a.text-dark:hover, a.text-dark:focus {
    color: #0a0a0a !important
  }

  .text-body {
    color: #fff !important
  }

  .text-muted {
    color: #888 !important
  }

  .text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important
  }

  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important
  }

  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
  }

  .text-decoration-none {
    text-decoration: none !important
  }

  .text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important
  }

  .text-reset {
    color: inherit !important
  }

  .visible {
    visibility: visible !important
  }

  .invisible {
    visibility: hidden !important
  }


  .alert {
    border: none;
    color: #fff
  }

  .alert a, .alert .alert-link {
    color: #fff;
    text-decoration: underline
  }

  .alert-primary {
    background-color: #375a7f
  }

  .alert-secondary {
    background-color: #444
  }

  .alert-success {
    background-color: #00bc8c
  }

  .alert-info {
    background-color: #3498DB
  }

  .alert-warning {
    background-color: #F39C12
  }

  .alert-danger {
    background-color: #E74C3C
  }

  .alert-light {
    background-color: #adb5bd
  }

  .alert-dark {
    background-color: #303030
  }

  //////////////////////////////////

  &__pagination {

    ul {
      li {
        button {
          color: #fff;
          border-color: #fff;

          &.Mui-selected {
            color: #3498DB;
            border: 1px solid rgba(52, 152, 219, 0.5);
            background-color: rgba(52, 152, 219, 0.12);
          }
        }

        div {
          color: #fff !important;
        }
      }
    }
  }

}