.wrapper {
  position: relative;
  width: 100%;
  z-index: 0;
  @media (max-width: 991px) {
    display: none;
  }
}

.image {
  position: absolute;
}