@import "../../../../../../scss/colors";

.ServiceCalcRows {
  //td, th {
  //  padding: 0 .5rem !important;
  //}
}

.ServiceCalcRow {
  height: 60px;
  //background-color: $main-2;
  border-bottom: 1px solid $main-1-3;
  padding: 0 0.5rem;
  //border-top: 1px solid $main-1-3;

  svg {
    path {
      transition: .3s ease-in;
    }

    &:hover {
      cursor: pointer;

      path {
        fill: rgba(0, 0, 0, .5);
        transition: .3s ease-in;
      }
    }
  }

  .rowCounter {
    display: flex;
    height: 60px;
   // padding: 0 0.75rem !important;

    & > div {
      height: 100%;
    }
  }
}

.ServiceCalcRow {

}

.WorkerCount {
  width: 50px;
  height: 100%;
  background: #F9F9F9;
  border: none;
  font-weight: bold;
}

.modalTitleContainer {
  padding: 30px;
  color: $main-5;
  text-align: center;
  font-weight: bold;
}

.modalDescContainer {
  padding: 0 30px;

  ul {
    padding: 0 0 0 20px;
  }

  li {
    list-style: disc;
  }
}

.modalPicContainer {
  padding: 0 30px;
  display: flex;
  justify-content: left;
  width: 100%;
}

.modalPic {
  width: 170px;
  height: auto;
}