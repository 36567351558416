@import "../../../scss/colors";

.LoaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
}

.Loader {
  display: inline-block;
  position: relative;

  svg {
    margin: auto;
    display: block;
    shape-rendering: auto;
    width: 164px;
    height: 164px;
  }
}


