.grid {
  &-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px;
    z-index: 0;
  }

  &-row {
    display: flex;
    height: 50px;

    &:nth-child(1) {
      & .grid-cell {
        position: relative;

        &:first-child:before {
          position: absolute;
          content: "0";
          top: 0;
          left: 0;
          transform: translate(-50%, -100%);
        }

        &:after {
          position: absolute;
          top: 0;
          right: 0;
          content: attr(data-index);
          transform: translate(50%, -100%);
        }
      }
    }
  }

  &-cell {
    border: 1px solid #ebebeb;
  }
}
