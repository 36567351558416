@import "colors";

.bg-main-1 {
  background-color: $main-1;
}
.bg-main-1-1 {
  background-color:#E4EBFC;
}

.bg-main-2 {
  background-color: $main-2!important;
}

.bg-main-3 {
  background-color: $main-3;
}

.bg-main-4 {
  background-color: $main-4;
}

.bg-main-5 {
  background-color: $main-5;
}

.bg-tranche-1 {
  //background-color: #88F61B !important;
  background-color: rgba(136,246,27, .2) !important;

}

.bg-tranche-2 {
  background-color: #6845BA !important;
}

.bg-light {
  background-color: #EEEEEE !important;
}

.bg-white-green {
  background-color: #9CE8AD !important;
}

.bg-gray-green {
  background-color: #95E1A6 !important;
}
.bg-blue-header {
  color:#fff!important;;
  background-color: $darkBlue!important;
}
.bg-blue-header-2 {
  color:#fff!important;;
  background-color: $blue!important;
}

