@import "colors";

p.Mui-error {
  position: absolute;
  bottom: -15px;
  left: 15px;
}
.MuiAppBar-root{
  z-index: 100;
}

.MuiFormHelperText-contained {
  margin: 0 !important;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.MuiInput-underline:before {
  border-bottom: 1px solid $main-3 !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid $main-4 !important;
}

.MuiInput-underline.Mui-error:after {
  border-bottom-color: #f44336 !important;
}

.MuiFormLabel-root.Mui-focused {
  //color: $main-4 !important;
}

.MuiPickersDay-daySelected .MuiIconButton-label {
  color: $main-1 !important;
}

.MuiSelect-icon {
  color: $main-5 !important;
}

.MuiSvgIcon-root {
  //fill: $main-5 !important;
}

.MuiRating-iconEmpty {
  color: rgba(255, 255, 255, .5) !important;
}

.MuiPickersToolbar-toolbar, .MuiPickersDay-daySelected {
  background-color: $main-4 !important;
}

.MuiPickerDTTabs-tabs {
  background-color: $main-4-07 !important;

  svg {
    fill: #fff !important;
  }
}

.PrivateTabIndicator-colorSecondary-3 {
  background-color: $main-4 !important;
}

.MuiPickersClockPointer-pointer, .MuiPickersClock-pin, .MuiPickersClockPointer-noPoint {
  background-color: $main-4 !important;
}

.MuiPickersClockPointer-thumb {
  border: 14px solid $main-4 !important;
}

.MuiFormLabel-root {
  font-size: .8rem;
}

.MuiMenu-list {
  height: 200px;
  overflow-y: scroll;
}

.MuiInputLabel-shrink {
  //opacity: 0;
}

.inputAdornment {
  svg * {
    fill: $main-5;
  }
}

.isInputAdornment {
  input {
    padding-left: 0 !important;
  }
}

.MuiPickersDay-day {
  span {
    color: $main-5;

    p {
      font-weight: 500;
    }
  }
}

.MuiPickersDay-dayDisabled {
  span {
    color: $main-2;
  }
}

.MuiIconButton-root {
  //display: none!important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 54px !important;
  height: 54px !important;
}

.MuiTypography-button {
  font-weight: 600!important;
}