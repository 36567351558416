.h20 {
  height: 20px;
}

.h30 {
  height: 30px;
}

.h40 {
  height: 40px;
}

.h50 {
  height: 50px;
}

.h60 {
  height: 60px;
}

.h70 {
  height: 70px;
}
.h80 {
  height: 80px;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}
