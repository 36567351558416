@import "../../vars";

.ActKsTopLine {
  width: 100%;
  height: $height_3;
  display: flex;
  justify-content: flex-end;
  font-size: $fontSize_2;
  color: $color;
  font-weight: 500;

  > div {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  > div:first-child {
    width: $width_1;
    justify-content: flex-start;
  }

  > div:nth-child(2) {
    width: $width_2;
    border-bottom: 1px solid $color;
    position: relative;
    span:first-child{
      color: $colorBlue;
      font-style: italic;
    }

    span:last-child {
      position: absolute;
      bottom: -1.05rem;
      font-size: $fontSize_1;
    }
  }

  > div:nth-child(3) {
    width: $width_3;
    justify-content: flex-end;
  }

  > div:last-child {
    width: $width_4;
    min-width: $width_4;
    margin-left: 10px;
    border-top: 1px solid $color;
    //border-bottom: 1px solid $color;
    border-left: 2px solid $color;
    border-right: 2px solid $color;
    border-bottom: none;
  }

}