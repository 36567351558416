@import "../../../../../../scss/colors";

.PersonalOrderItem {
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: .3s ease;
  height: 70px;

  &:hover {

    background-color: rgba(255, 255, 255, .5);
  }

  &_status {
    //width: 25%;
  }

  &_dialog {
    cursor: pointer;
  }

  a {
    display: flex;
    color: currentColor;
    text-decoration: none;


    &:hover {
      text-decoration: none;
    }
  }

  &_name {
    margin-left: 30px;
    width: 50%;
    //@media screen and(max-width: 1300px) {
    //  width: 30%;
    //}
  }
  &_sum {
    padding: 0 0 0 15px;
  }

  &_date {
    margin-top: 5px;
    font-size: .8rem;
    color: #BEBEBE;

  }

  &_payment {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $main-4;
    border-radius: 3px;
    color: #fff;
    height: 30px;
    flex: 1 0 15%;
    @media screen and(max-width: 992px) {
      height: auto;
    }

    span {
      color: #fff;
      text-align: center;
    }
  }

  &__cancel {
    cursor: pointer;
    color: #8764DA;
    transition: .3s ease-in-out;
    display: flex;

    svg {
      fill: #8764DA;
    }

    &:hover{
      text-decoration: underline;
    }
  }
}