@import "../../vars";

.ActKSTableHeading {
  display: flex;
  flex-direction: column;

  font-size: $fontSize_2;
  color: $color;
  font-weight: 500;

  &_sum{
    color: $colorBlue;
    font-style: italic;
  }
}