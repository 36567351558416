.ChatInput {

  display: flex;
  align-items: center;
  width: 100%;

  input {
    border: none;
    width: 90%;
    height: 30px;
    font-weight: 500;
    font-size: 1.1rem;
  }

  &-btn {
    margin-left: 15px;

    button {
      border: none;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .3s ease-in-out;
      background-color: #C3B1EC;
      box-shadow: 0px 10px 20px rgba(135, 100, 218, 0.4);

      &:hover {
        background-color: #8764da;
      }
    }

    svg {
      width: 14px;
      height: 14px;
    }
  }

  input.focus {
    & ~ .ChatInput-btn {
      button {
        background-color: #8764da;
      }
    }
  }

}