@import "scss/colors";
@import "scss/fonts";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  height: 100%;
  background-color: #F1F2FA;
  @media screen and (max-width: 1299px) {
    overflow-x: auto;
    overflow-y: auto;
  }

}

*:focus {
  outline: none !important;
}

li {
  list-style: none;
}


.modal-backdrop.show {
  opacity: 0.9;
  background: $main-4;
}

h1, h2, h3, h4, h5, html {
  //font-family: $PFDinDisplayPro !important;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@media (max-width: 700px) {
  html {
    font-size: 12px;
  }
}

body * {
  //font-family: $PFDinDisplayPro !important;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

::-webkit-scrollbar-button {
  //background-image: url('');
  background-repeat: no-repeat;
  width: 10px;
  height: 10px
}

::-webkit-scrollbar-button:horizontal {
  //background-image: url('');
  background-color: #fff;
  width: 0;
}

::-webkit-scrollbar-track:no-button:horizontal {

}


::-webkit-scrollbar-track {
  background-color: #DEDEDE;

}

::-webkit-scrollbar-track:horizontal {
  background-color: #DEDEDE;

}

::-webkit-scrollbar-track-piece:horizontal {

}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0;
  border-radius: 0;
  background-color: #9DA2C4;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $main-4;
  height: 4px;
}

::-webkit-resizer {
  //background-image: url('');
  background-repeat: no-repeat;
  width: 4px;
  height: 4px
}

::-webkit-scrollbar {
  height: 2px;
  width: 10px; /* width of vertical scrollbar */
  border: 1px solid #9DA2C4;
}

.worker_label {
  background-color: #fff;
  //color: #CDC8D7;
  color: #292929;
  font-size: .9rem;

  &.focus {
    color: #8764DA;
  }
}

.MuiInput-inputMultiline {
  padding-left: 15px !important;
}

.worker_input {
  margin: .6rem 0 !important;
  background-color: $main-1-1;
  width: 100%;
  padding: 5px 15px;

  > div {
    width: 100%;
    min-height: 100%;
  }

  input {
    height: 40px;
    max-height: 100%;
    padding: 0 0 0 15px !important;

    &:disabled {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  svg {

  }

  .table th, .table td {
    padding: 0.75rem 0 !important;
  }
}

.first-letter:first-letter {
  text-transform: capitalize
}

