@import "../../../../../scss/colors";

.PersonalOrderItemPS {
  &__header {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 1.2rem;
    font-size: 1rem;
    cursor: pointer;
    flex-grow: 1;
    text-transform: uppercase;
    background: $main-4;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    transition: .3s ease-out;
    width: 100%;
    font-weight: 500;
    color: #fff;
    position: relative;
  }

  &__info {
    display: flex;
    padding: 1.2rem;
    margin: 0 1px;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  &__text {
    color: $main-4;
    margin: 0 50px 0 5px;
    font-weight: 600;
  }

  &__system {
    display: flex;
    flex-direction: column;
    padding: 1.4rem 1.6rem;
    width: calc(50% - 1rem);
    min-height: 180px;
    margin: 0 .5rem;
    cursor: pointer;
    transition: .3s ease-in-out;
    @media screen and (max-width: 575px) {
      width: 100%;
      margin: .5rem;
    }

    &:hover {
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.3),
      0px 1px 2px 0px rgba(0, 0, 0, 0.24),
      0px 1px 3px 1px rgba(0, 0, 0, 0.22);
    }

    &-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 1rem -0.5rem;
    }

    &-checkbox {
      width: 22px;
      min-width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 1px solid #D7DAED;
      margin-right: 1rem;
      position: relative;

      &.active {
        border: 1px solid $main-4;

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 60%;
          height: 60%;
          border-radius: 50%;
          background-color: $main-4;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &-description {
      padding: 10px 0 0 40px;
    }

    &-picture {
      padding: 15px 0 0 40px;

      img {
        height: 50px;
        object-fit: contain;
      }
    }
  }
}