.Chat {
  position: relative;
  display: flex;
  background-color: #ECEEFB;
  min-width: 100%;
  min-height: 100%;
  .jq{
    background-color: black;
  }

  &_empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_dialogs {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 600px;
    height: 600px;
    overflow: hidden;
    overflow-y: scroll;
    padding: 0 0 20px;
    position: relative;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      height: 3px;
      width: 3px;
      border: 1px solid #9DA2C4;
    }



    &_input {
      background-color: #fff;
      height: 50px;
      width: 100%;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

  }
}