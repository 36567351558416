.estimate {
  &__sub-categories {
    &-selected-category {
      width: 75px;
      height: 75px;
      border-radius: 15px;
      object-fit: cover;
      margin-right: 30px;
      &-text {
        font-size: 1.2em;
      }
    }
    &-name {
      font-weight: 500;
      font-size: 14px;

      
      line-height: 22px;
      color: #8594a2;
      position: relative;
      padding: 0 0 0 30px;
  
      &:before {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        left: 0px;
        top: 50%;
        border-radius: 50%;
        transform: translateY(-50%);
        background: #c9d6e1;
      }
  
      &.active {
        color: #5d20c1;
  
        &:before {
          background: #88f61b;
        }
      }
    }
  
    &-icon {
      fill: #5d20c1 !important;
    }
  
    &-service {
      margin: 0 25px 10px;
      border: 1px solid #c9d6e1;
      border-radius: 20px;
      transition: 0.1s ease-in;
      min-height: 40px;
      display: flex;
      font-size: 12px;
      align-items: center;
  
      &-name {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #000000;
      }

      label {
        cursor: pointer;
        width: 100%;
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 38px;
        padding: 0 20px;

        @media (max-width: 1199px) {
          height: auto;
          padding: 10px;
        }
        @media (max-width: 991px) {
          flex-direction: column;
        }
      }

      &-count {
        border: none;
        margin-right: 10px;
        max-width: 50px;
        text-align: center;
        border-radius: 10px;

        
        &-text {
          height: 22px;
          display: flex;
          align-items: center;
          padding: 0 8px;
          margin-right: 15px;
          background-color: #fff;
          border-radius: 10px;

          svg {
            margin-left: 5px;
          }
        }

        @media (max-width: 400px) {
          max-width: 30px;
        }
      }

      &-unit {
        color: #fff;
      }
  
      
      &.active {
        background: #7b53da;
        font-weight: normal;
        line-height: 20px;
  
        span {
          color: #ffffff;
        }
      }
      &-right {
        min-width: 235px;
        display: flex;
        align-items: center;
        margin-left: 20px;
        @media (max-width: 991px) {
          margin-top: 10px;
        }
        @media (max-width: 400px) {
          margin-left: 0;
          min-width: 210px;
        }
      }

      @media (max-width: 500px) {
        margin: 0 0 10px;
      }
    }
  
    &-accordion {
      box-shadow: none !important;
      border-bottom: 1px solid #dee7ef;
  
      &:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 12px 0;
    }
  }
}