@import "../../vars";

.ActKS3TableBody {
  display: flex;
  width: 100%;
  margin: 10px 0;
  font-size: $fontSize_2;
  font-weight: 500;
  color: $color;
  line-height: $fontSize_2;

  &_column {

    div {

    }

    > div {
      padding: 0 .5rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .num {
      height: $height_1;
      min-height: $height_1;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .name {
      height: $height_2;
      min-height: $height_2;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .medium {
      height: $height_3;
      min-height: $height_3;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .big {
      height: $height_5;
      min-height: $height_5;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .wl{
      width: 100px;
    }
    .wm{
      width: 125px;
    }

  }
}