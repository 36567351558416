.IndividualWorks {
  display: flex;
  flex-direction: column;
  min-height: inherit;
  justify-content: space-between;
  border-radius: 20px;

  &__title {
    &-small {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #8594A2;
      @media screen and (max-width: 575px) {
        margin: 0 5px 0 0;
        font-size: 10px;
      }
    }

    &-sum {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      @media screen and (max-width: 575px) {
        font-size: 12px;
        margin-top: -10px;
        * {
          font-size: 12px;
        }
      }
    }

    &-num {
      margin: 0 4px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 24px;
      background: #FFFFFF;
      border-radius: 6px;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      padding: 2px 0 0;
    }
  }

  &__header {
    padding: 27px 68px;
    border-radius: 20px 20px 0 0;
    @media screen and (max-width: 575px) {
      padding: 20px;
    }
  }

  &__body {
    padding: 0 68px;
    @media screen and (max-width: 575px) {
      padding: 0 10px;
    }
  }

  &__categories {
    display: flex;
    flex-wrap: wrap;

    &-item {
      width: 162px;
      padding: 40px 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: 575px) {
        padding: 20px 0 0;
      }

      &:hover {
        * {
          color: #7B53DA;
        }

        .IndividualWorks__categories-item-name-with-arrow:after {
          opacity: 1;
        }
      }

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        box-shadow: 0 13px 12px -7px rgba(0, 54, 112, 0.16);
        border-radius: 20px;
      }

      &-name {
        text-align: center;
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        transition: .25s ease-in;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        max-width: 100%;
        @media screen and (max-width: 575px) {
          margin-top: 5px;
          font-size: 10px;
          line-height: 18px;
        }

        &-with-arrow {
          display: flex;
          flex-wrap: nowrap;
          position: relative;
          transition: .25s ease-in;
          padding: 0 30px;

          &:after {
            content: '';
            display: block;
            background: url('../../../../img/arrow-right.png');
            background-repeat: no-repeat;
            width: 20px;
            height: 16px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            opacity: 0;
            transition: .25s ease-in;
          }
        }
      }
    }
  }

  &__sub-categories {
    &-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #8594A2;
      position: relative;
      padding: 0 0 0 30px;

      &:before {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        left: 0;
        top: 50%;
        border-radius: 50%;
        transform: translateY(-50%);
        background: #C9D6E1;
      }

      &.active {
        color: #5D20C1;

        &:before {
          background: #88f61b;
        }
      }
    }

    &-icon {
      fill: #5D20C1 !important;
    }

    &-service {
      margin: 0 25px 10px;
      padding: 5px 20px;
      border: 1px solid #C9D6E1;
      border-radius: 20px;
      cursor: pointer;
      transition: .1s ease-in;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 575px) {
        margin: 0 5px 10px;
      }

      &-name {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #000000;
      }

      &-price {
        color: #8594A2;
        padding: 0 0 0 50px;
      }

      &.active {
        background: #7B53DA;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;

        div {
          color: #FFFFFF;
        }
      }
    }

    &-accordion {
      box-shadow: none !important;
      border-bottom: 1px solid #DEE7EF;

      &:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      max-height: 400px;
      overflow-y: auto;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
    cursor: pointer;

    &-img {
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      box-shadow: 0 13px 12px -7px rgba(0, 54, 112, 0.16);
      border-radius: 10px;
      margin-left: 22.5px;
    }

    &-name {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      margin-left: 18px;
      color: #000000;
    }

    &-count {
      color: #8594A2;
      margin: 2px 0 0 5px;
    }
  }

  &__steps {
    display: flex;
    align-items: center;

    &-step {
      border-bottom: 1px solid #C9D6E1;
      padding: 7px;
      position: relative;
      flex: 1 0 auto;

      &:first-child {
        &:before {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          left: -5px;
          bottom: -5px;
          border-radius: 50%;
          background: #C9D6E1;
          position: absolute;
          //background: #88f61b;
        }
      }

      &_active, &_done {
        &:after {
          position: absolute;
          content: '';
          display: block;
          width: 50%;
          height: 1px;
          background: #88f61b;
          bottom: -1px;
          left: 0;
        }

        &:first-child {
          &:before {
            background: #88f61b;
          }
        }
      }

      &_done {
        &:after {
          width: 100%;
        }
      }

      &-title {
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        text-align: center;
        text-transform: uppercase;
        color: #8594A2;
        @media screen and (max-width: 575px) {
          text-align: left;
          font-size: 10px;
        }
      }

      &-success {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background: #FFFFFF;
        border: 1px dashed #C9D6E1;
        position: absolute;
        right: -15px;
        bottom: -15px;
        z-index: 1;

        svg {
          transition: .25s ease-in;
        }

        &_done {
          border: 1px dashed #88f61b;

          svg * {
            stroke: #88f61b;
          }
        }
      }
    }

    @media screen and (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;

      &-step {
        border-bottom: none;
        border-left: 1px solid #C9D6E1;
        &::before {
          left: 0;
          bottom: auto;
          top: 0;
        }

        &-title {
          font-size: 8px;
        }
        &-success {
          bottom: 0;
          right: auto;
          left: 0;
          transform: translate(-50%, 50%);
          width: 14px;
          height: 14px;
        }
        &_active,
        &_done {
          border-color: #88f61b;
          > div {
            color: #88f61b;

          }
        }
        &_active:after,
        &_done:after {
          width: 0;
          height: 0;
        }
      }
    }
  }

  &__footer {
    border-radius: 0 0 20px 20px;
    overflow: hidden;
    height: 58px;
    display: flex;
    align-items: center;
    position: relative;
    top: 1px;
    background: #DEE7EF;
    min-width: 100%;
    margin: 40px 0 0;

    @media screen and (max-width: 575px) {
      flex-wrap: wrap;
      height: auto;
      border-radius: 0 0 20px 20px;
      justify-content: space-between;
      background: transparent;
      width: calc(100% - 20px);
      min-width: 1px;
      margin: 10px auto 0;
      padding-bottom: 20px;
      top: 0;

      &.IndividualWorks__footer-first {
        justify-content: center;
      }
    }
  }

  &__button {
    background: #DEE7EF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    height: 58px;

    &-title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #FFFFFF;
      user-select: none;
      @media screen and (max-width: 575px) {
        font-size: 10px;
        line-height: 12px !important;
      }
    }

    &_active {
      background: #88f61b;
      cursor: pointer;
      @media screen and (max-width: 575px) {
        max-width: 100%;
        width: 50%;
        padding: 0 5px;
      }
    }

    &_back {
      background: #FFFFFF;
      border: 1px solid #C9D6E1;
      box-shadow: 0 56px 35px -33px rgba(0, 34, 86, 0.2);
      border-radius: 0 0 0 20px;
      cursor: pointer;
      max-width: 250px;
      width: 250px;

      > div {
        color: #8594A2;
      }
    }

    &_forward {
      max-width: 250px;
      width: 250px;
      
    }

    @media (max-width: 575px) {
        max-width: none;
        width: auto;
        border-radius: 10px;
        height: 28px;
        display: inline-flex;
        flex: 0 1 auto;
        padding: 0 20px;
    }
    @media(max-width: 330px) {
      padding: 0 10px;
    }
  }

  &__conditions {
    margin: 15px 0 0;

    &-desc {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      width: 75%;
      margin: 20px auto;
      @media screen and (max-width: 575px) {
        width: 100%;
        font-size: 12px;
        line-height: 14px;
        margin: 15px auto 10px;
      }
    }

    &-docs {
      display: flex;
      align-items: center;
      background: rgba(222, 231, 239, .5);
      border-radius: 10px;
      padding: 24px 30px;
      margin: 0 0 0 30px;
      flex: 1 0 auto;

      @media screen and (max-width: 575px) {
        flex-direction: column;
        margin: 20px 0 0;
        padding: 10px 0;
        width: 100%;
      }

      &-left {
        display: flex;
        flex-direction: column;
        border-right: 1px solid rgba(133, 148, 162, .5);
        padding-right: 50px;
        @media screen and (max-width: 575px) {
          border: none;
          width: 100%;
          padding: 2%;
        }
      }

      &-right {
        padding-left: 50px;
        //@media screen and (max-width: 1500px) {
        //  padding: 0;
        //}

        .MuiCheckbox-colorPrimary.Mui-checked {
          color: #7B53DA;
        }

        svg.MuiSvgIcon-root {
          width: 20px;
          height: 20px;
        }

        span.MuiFormControlLabel-label {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #8594A2;
        }
      }
    }

    &-list {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      border-top: 1px solid #DEE7EF;
      border-bottom: 1px solid #DEE7EF;
      padding: 25px 0;
      font-size: 12px;
      line-height: 20px;
      font-weight: normal;
      @media screen and (max-width: 575px) {
        flex-direction: column;
      }

      &-item {
        position: relative;
        padding: 0 0 0 5px;
        margin: 5px 0;

        &:before {
          content: '';
          display: block;
          border-radius: 50%;
          position: absolute;
          width: 10px;
          height: 10px;
          top: 50%;
          left: -5px;
          transform: translate(-50%, -50%);
          background: #7B53DA;
        }
      }
    }

    &-switchers {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      @media screen and (max-width: 575px) {
        flex-direction: column;
      }
    }
  }

  &__choice {
    background: rgba(222, 231, 239, .5);
    padding: 22px;
    border-radius: 20px;
    margin: 22px 0 0;

    @media (max-width: 575px) {
      padding: 10px;
      margin: 5px 0 0;
    }

    &-item {
      display: flex;
      cursor: pointer;

      &:not(:first-child) {
        margin: 20px 0 0;
      }

      &-success {
        border: 1px solid #8594A2;
        border-radius: 20px;
        width: 20px;
         idth: 20px;
        height: 20px;
        margin: 5px 0 0 0;
        position: relative;

        &_active {
          border: 1px solid #7B53DA;
          transition: .25s ease-in;

          &:after {
            transition: .25s ease-in;
            content: '';
            position: absolute;
            border-radius: 50%;
            background: #7B53DA;
            width: 14px;
            height: 14px;
            display: block;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #8594A2;
        transition: .25s ease-in;

        &_active {
          color: #000;
        }
      }

      &-desc {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 16px;
        text-align: center;
        color: #8594A2;
      }

      @media (max-width: 575px) {
        align-items: center;
        &-title {
          font-size: 12px;
          text-align: left;
          line-height: 120%;
        }
        &-desc {
          font-size: 10px;
          text-align: left;
        }
        &-success {
          width: 16px;
          height: 16px;
          min-width: 16px;
          margin: 0;
          &_active:after {
            width: 9px;
            height: 9px;
          }
        }
        &:not(:first-child) {
          margin: 10px 0 0;
        }
      }
    }
  }

  &__additions {
    margin: 15px 0 0;
  }

  &__top {
    display: flex;
    align-items: center;
    min-height: 50px;
    background: rgba(222, 231, 239, .5);
    border: 1px solid rgba(222, 231, 239, .5);
    border-radius: 10px;
    width: 100%;
    justify-content: space-between;
    padding: 0 30px 0 0;
    @media screen and (max-width: 575px) {
      flex-wrap: wrap;
      padding: 10px;
    }

    &-img {
      width: 50px;
      height: 50px;
      box-shadow: 0 13px 12px -7px rgba(0, 54, 112, 0.16);
      border-radius: 10px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    &-name {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      margin: 0 18px;
      padding: 5px 0;
    }

    &-price {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: right;
      color: #000000;
      white-space: nowrap;
    }

    &-sub {
      &-title {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #8594A2;
        white-space: nowrap;
        @media screen and (max-width: 575px) {
          margin: 0 5px 0 0;
        }
      }

      &-num {
        white-space: nowrap;
      }
    }

  }

  &__confirm {
    //font-style: normal;
    //font-weight: normal;
    //font-size: 12px;
    //line-height: 20px;

    &-top {
      background: rgba(222, 231, 239, .5);
      border: 1px solid #DEE7EF;
      border-radius: 10px;
      padding: 25px;

      &-main {
        //width: 33.33%;
        width: auto;
        @media screen and (max-width: 575px) {
          width: 100%;
        }
      }

      &-total {
        display: flex;
        align-items: center;
        margin: 0 80px 0 0;
        @media screen and (max-width: 575px) {
          flex-direction: column;
          margin: 0;
          & ~ div svg {
            width: 14px;
            height: 18px;
          }
        }

        > div:first-child {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.04em;
          text-transform: uppercase;
          color: #8594A2;
          margin: 0 10px 0 0;
          @media screen and (max-width: 575px) {
            font-size: 12px;
          }
        }

        > div:last-child {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 26px;
          text-align: center;
          @media screen and (max-width: 575px) {
            font-size: 14px;
          }
        }

        &-count {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 26px;
          text-align: center;
          margin: 0 0 0 10px;
          @media screen and (max-width: 575px) {
            font-size: 14px;
          }
        }
      }
    }

    &-img {
      width: 31px;
      height: 31px;
      box-shadow: 0 13px 12px -7px rgba(0, 54, 112, 0.16);
      border-radius: 6px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin: 0 15px 0 0;
    }
  }

  &__bottom-total {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    @media screen and (max-width: 575px) {
      padding: 10px;
      flex: 0 1 auto;
      margin-bottom: 10px;
      border-radius: 20px;
      padding: 10px;
      background: rgba(222, 231, 239, .5);
      flex-wrap: wrap;
    }

    &-box {
      display: flex;
      flex-direction: column;
      width: 33.33%;

      &-group {
        display: flex;
        align-items: center;
        @media screen and (max-width: 575px) {
          width: 100%;
        }
      }

      &-count {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (max-width: 575px) {
          flex-direction: row;
          align-items: center;
          margin-bottom: 5px;
        }
      }

      @media screen and (max-width: 575px) {
        width: 100%;
        &:nth-child(-n + 2) {
          width: 50%;
        }
      }
    }
  }
}