.section {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  margin-top: 70px;
  position: relative;
  z-index: 1;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 44px;
  text-align: center;
  color: #303030;
  margin-bottom: 30px;
}

.text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 40px;
}

.picture {
  max-width: 100%;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 36px 35px -33px rgba(0, 34, 86, 0.2);
  border-radius: 10px;
  margin-bottom: 40px;
  transition: all .7s;
}

.picture:hover {
  box-shadow: 0px 56px 35px -33px rgba(0, 34, 86, 0.2);
  transform: translateY(-15px);
}

.button {
  text-transform: uppercase;
}

@media (max-width: 575px) {
  .section {
    margin-top: 10px;
  }
  .title {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .text {
    font-size: 12px;
    line-height: 18px;
  }
}