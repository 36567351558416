@import "colors";

.text-main-1 {
  color: $main-1 !important;
}
.text-main-1-2 {
  color: $main-1-2 !important;
}

.text-main-2 {
  color: $main-2 !important;
}

.text-main-3 {
  color: $main-3 !important;
}

.text-main-4 {
  color: $main-4 !important;
}

.text-main-5 {
  color: $main-5 !important;
}

.text-sum {
  //color: $main-4 !important;
  color: #fff !important;
  font-weight: bold;

  * {
    //color: $main-4 !important;
    color: #fff !important;
    font-weight: 500;
  }
}