@import "../../../scss/colors";
.GreenButton {
  background: $green-1;
  color: #fff;
  height: 40px;

  display: flex;
  border-radius: 20px!important;
  align-items: center;

  flex-grow: 0;
  justify-content: center;

  cursor: pointer;
  padding: 0 20px;

  line-height: 100%;
  text-align: center;
  transition: .2s ease-in-out;
  //font-weight: 600;
  width: fit-content;

  &:hover {
    text-decoration: none;
    background: #71E200;
    filter: drop-shadow(0 10px 10px rgba(136, 246, 27, 0.2));
    color: #fff;
  }
  &:disabled {
    background: #cecece;
    color: #fff;
    &:hover{
      background: #cecece;
      color: #fff;
      filter: drop-shadow(0 10px 10px rgba(206, 206, 206, 0.4));
    }
    pointer-events: none;
  }

  @media screen and (max-width: 767px) {
    min-width: 100%;
  }
  @media (max-width: 575px) {
    height: 37px;
  }
}