@import "../../../scss/colors";
@import "../../../scss/backgrounds";

.wc {

  padding: 70px 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-label, &-label span {
    color: $main-1-2;
  }

  &-input {
    //background-color: $main-1-1;
    //height: 40px;
    border-radius: 3px;

    input {
      padding-left: 15px;
    }

    > div {
      width: 100%;
      height: 100%;

      label {
        top: -10px;
        left: 15px;
        color: rgba(29, 29, 29, .5);
      }

      > div {
        min-height: 100%;
        margin: 0;

      }
    }
  }

  &-select {
    > div > div {
      padding: 0 15px;
    }
  }

  &-nav {
    display: flex;

    li {
      margin-right: 5%;
    }

    &__link {
      color: $main-5;
      font-weight: 600;
      font-size: 1.2rem;
      opacity: .5;
      cursor: pointer;

      &_active {
        opacity: 1;
      }
    }
  }

  &__error {
    > div {
      border-bottom: 2px solid #f44336 !important;
      border-top: 2px solid #f44336 !important;
      color: #f44336;
    }
  }
}

