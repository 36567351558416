@import "../ActKSComponents/vars";

@page {
  size: landscape;
}


.ActPDFViewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .6);
  z-index: 999;


  #divToPrint {
    position: static;
    overflow: visible;
    background-color: #fff;

    &.rotate {
      transform: rotate(-90deg);
    }

    .ActKS2 {
      transform: scale(.95);

    }

    .ActKS3 {
      transform: scale(.9);
    }


  }

  @media print {
    #divToPrint {
      //size: landscape;
      size: portrait;
    }
  }

  &_btns {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 100;
    top: -50px;
    padding: 5px 0 0 10px;
    background-color: rgba(0, 0, 0, .8);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    animation: slideDown .3s ease-in-out forwards;
  }

  &_btn {
    margin: 10px 0 0 10px !important;
    width: 100px;

    &:last-child {
      margin: 10px 10px 0 10px !important;
    }
  }

  form {
    display: flex;
    font-size: .9rem;

    label {
      color: #fff !important;
    }

    input {
      height: 34px;
      width: 100px;
    }

    .field:last-child {
      margin-left: 10px;
    }
  }

  form:last-child {
    margin: 0 10px 0 5px;
  }

}

@keyframes slideDown {
  0% {
    top: -50px;
  }
  100% {
    top: 0;
  }
}
