.wrapper {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

.content {
  max-width: 500px;
  width: 100%;
  border-radius: 20px;
  background-color: #fff;
  padding: 25px 10px 30px;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin-bottom: 15px;
}

.title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 35px;
}

.text {
  text-align: center;
  margin-top: -25px;
  font-size: 16px;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 369px;
  width: 100%;
}

.inputWrapper {
  margin: 5px 0;
  width: 100%;
}

.button {
  height: 50px;
  width: 100%;
  margin: 30px 0 15px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.policy {
  align-self: center;
}