.estimate {
  &-docs {
    padding: 24px 30px;
    background: rgba(222, 231, 239, 0.5);
    border-radius: 10px;
    &-left {
      border-right: 1px solid rgba(133, 148, 162, 0.5);
      padding-right: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &-right {
      padding: 6px 0 6px 50px;
    }

    @media (max-width: 991px) {
      padding: 16px;
      &-left {
        padding-right: 30px;
      }
      &-right {
        padding: 6px 0 6px 30px;
      }
    }

    @media (max-width: 660px) {
      flex-wrap: wrap;
      &-left {
        padding-right: 0px;
        width: 100%;
        border-right: none;
      }
      &-right {
        padding: 6px 0 6px 0px;
        width: 100%;
        margin-top: 15px;
      }
    }
    
  }
  &-supply-block {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
