.Calculator {
  position: relative;
  z-index: 3;
  height: 100%;
  padding: 260px 38px 0;
  margin: 85px 0 0;
  @media screen and (max-width: 575px) {
    margin: 35px 0 0;
    padding: 255px 0 0;
  }

  &__layout {
    top: 0;
    border-radius: 30px;
    background: #7B53DA;
    position: absolute;
    z-index: 0;
    height: 643px;
    padding: 38px;
    @media screen and (max-width: 575px) {
      padding: 20px;
    }

    &-title {
      font-weight: 900;
      font-size: 3rem;
      line-height: 44px;
      text-align: center;
      color: #71E200;
      @media screen and (max-width: 575px) {
        font-size: 14px;
        line-height: 34px;
      }
    }

    &-desc {
      margin: 20px auto 0;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;
      width: 85%;
      @media screen and (max-width: 575px) {
        font-size: 12px;
        margin: 10px 0 0;
        line-height: 18px;
        width: 100%;
      }
    }

    &-heading {
      font-weight: normal;
      font-size: 24px;
      line-height: 22px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #FFFFFF;
      position: relative;
      margin: 40px auto 25px;
      width: 90%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 575px) {
        font-size: 18px;
        line-height: 22px;
        margin: 25px auto 15px;
      }

      &-line {
        background: #fff;
        opacity: 0.4;
        border-radius: 10px;
        flex: 1 0 auto;
        height: 1px;
      }

    }
  }

  &__content {
    width: 85%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    min-height: 613px;

    &-header {
      box-shadow: 0 56px 35px -33px rgba(0, 34, 86, 0.2);
      border-radius: 20px 20px 0 0;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      height: 55px;
      @media screen and (max-width: 575px) {
        height: auto;
        flex-wrap: wrap;
      }
    }

    &-body {
      // border: 1px solid #DEE7EF;
      background: #FFFFFF;
      box-shadow: 0 56px 35px -33px rgba(0, 34, 86, 0.2);
      border-radius: 0 0 20px 20px;
      min-height: 412px;

      &_round {
        border-radius: 20px;
      }

      &-in-development {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #7B53DA;
        font-weight: normal;
        font-size: 24px;
        flex-direction: column;
        min-height: inherit;
      }
    }

    @media (max-width: 400px) {
      min-width: 95%;
    }
  }


}