@import "../../vars";

.ActKsBottomItem {
  display: flex;
  flex-direction: column;

  > div {
    font-size:$fontSize_2;
    text-align: center;
    position: relative;
    height: $height_4;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    > div {
      width: 100%;
      text-align: center;
    }

    div:last-child {
      font-size: $fontSize_1;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: $height_4;
    }
  }
}