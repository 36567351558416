@keyframes inputShow {
  0% {
    opacity: 0;
    width: 0;
    border: 1px solid transparent;
    color: $main-1-1;
  }
  100% {
    opacity: 1;
    width: 100%;
    padding: 0 15px;
    border: 1px solid $main-1-3;
    color: rgba(41, 41, 41, 1);
  }
}


@keyframes inputHide {
  0% {
    opacity: 1;
    width: 100%;
    border: 1px solid $main-1-3;
    padding: 0 15px;
    color: rgba(41, 41, 41, 1);
  }
  100% {
    opacity: 0;
    width: 0;
    border: 1px solid transparent;
    color: $main-1-1;
  }
}

@keyframes slideDown {
  0% {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    padding: 0 30px;
  }

  100% {
    max-height: 200px;
    opacity: 1;
    visibility: visible;
    padding: 15px 30px 30px;
  }
}

@keyframes slideUp {

  0% {
    max-height: 200px;
    opacity: 1;
    visibility: visible;
    padding: 15px 30px 30px;
  }

  100% {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    padding: 0 30px;
  }
}

@keyframes fadeIn{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
@keyframes fadeOut{
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}
