.AdLoader {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  svg {
    margin: auto;
    display: block;
    shape-rendering: auto;
    width: 120px;
    height: 120px;
  }
}