.Td {
  position: relative;
  height: inherit;
  padding:0.75rem 0!important;
}

.TdItem {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: .9rem !important;
  //justify-content: flex-start;
  //justify-content: center;
  font-weight: 500;
  //padding: 0 10px;

  div {
    text-align: center;
  }

  @media screen and (max-width: 575px) {
    font-size: 0.7rem !important;
  }
}