@import "../../../../../../scss/colors";

.tabs {
  display: flex;
  width: 100%;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    padding: 0 15px;
    font-size: 1rem;
    cursor: pointer;
    flex-grow: 1;
    text-transform: uppercase;
    //background-color: #fff;
    //filter: drop-shadow(0 -2px 5px rgba(0, 0, 0, .06));
    background: $main-4;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    transition: .3s ease-out;
    width: 50%;
    font-weight: 500;
    color: #fff;
    position: relative;
    top: 1px;
  }

  & > div:first-child {
    margin-right: 3px;
  }

  & > div:last-child {
    margin-left: 3px;
  }

}

//div.active {
//  background: $main-4;
//  color: #fff;
//  filter: drop-shadow(0 -5px 5px rgba(0, 0, 0, .04));
//
//  strong {
//    color: #fff;
//  }
//}

div.active {
  background: #fff;
  color: #000;
  top: 0;
  //filter: drop-shadow(0 -5px 5px rgba(0, 0, 0, .04));

  strong {
    color: #000;
  }
}

.single {
  max-width: 50%;
  margin-left: 0!important;
  @media screen and (max-width: 575px) {
    max-width: 100%;
  }
}
