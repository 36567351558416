.w {
  &__top-nav {
    background-color: #7F5CD4;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    position: fixed;
    z-index: 100;

    &-left {
      display: flex;
      align-items: center;
    }

    &-title {
      color: #fff;
      font-size: 1.2rem;
      font-weight: 500;
      padding: 0 0 0 50px;
      letter-spacing: .08rem;
      text-transform: uppercase;
    }

    &-link {
      transition: .2s ease-in;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;

      > a {
        display: flex;
        align-items: center;

        &:hover {
          text-decoration: none;

        }
      }

      &:hover {
        opacity: .6;
      }

      svg {
        fill: #fff;
        width: 30px;
        height: 30px;
      }
    }

    &-logo {
      border-left: 5px solid #fff;
      border-right: 5px solid #fff;
      width: 180px;
      height: 50px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 70%;
    }
  }

  &-photo {
    display: block;
    overflow: hidden;

    img {
      width: 100%;
    }

    &:hover {
      .w-photo__upload {
        bottom: 0;
      }
    }

    &__upload {
      position: absolute;
      //bottom: -50px;
      bottom: 0;
      background: rgba(0, 0, 0, .25);
      height: 50px;
      width: 100%;
      color: #fff;
      transition: .3s ease-out;
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, .35);
      }

      svg * {
        fill: #fff;
      }

      span {
        color: #fff;
        font-size: .8rem;
        margin-left: 10px;
      }
    }


  }

  &__footer {
    display: flex;
    padding: 0 40px;
    align-items: center;
    height: 50px;
    background-color: #7F5CD4;
    color: #fff;

    > div {
      width: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      border-left: 5px solid #fff;
      border-right: 5px solid #fff;
    }
  }

  &__home-link {
    position: fixed;
    top: 50px;
    left: 100px;
    transition: .2s ease-in;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    padding: 5px 10px;
    background-color: #fff;
    //text-transform: uppercase;


    > a {
      display: flex;
      align-items: center;

      &:hover {
        text-decoration: none;

      }
    }

    &:hover {
      opacity: .6;
    }

    svg {
      fill: #7b53da;
      width: 30px;
      height: 30px;
    }
    span {
      color: #7b53da;
      font-weight: bold;
    }

    @media (max-width: 1000px) {
      position: static;
    }
  }

  &__auth-footer{
    color: #fff;
    position: fixed;
    right: 100px;
    bottom: 50px;
    font-size: 1.4rem;
  }

}