@import "src/scss/colors";

.AdminClientList {
  &__price {
    width: 200px;
    height: 40px;
    padding: 0 50px 0 0 !important;
    display: flex;
    align-items: center;

    &_title {
      padding: 0 50px 0 5px !important;
    }

    &-num {
      padding: 0 0 4px 18px;
      letter-spacing: 0.20px;
    }
  }

  &__lock {
    cursor: pointer;
    fill: #929292 !important;
  }

  &__detail {
    background-color: $main-1;
    padding: 1.5rem;
    border-radius: 10px;
  }

  &__btn {

  }

  &__save {
    margin-right: 40px !important;
  }

  &__column-text {
    height: 16px;
  }

  &__service-list {
    li {
      cursor: pointer;
      margin: 2px 0;

      > div {
        transition: .25s ease-in;
      }

      &:hover {
        > div {
          background: rgba(0, 0, 0, .1);
        }
      }
    }
  }
}