.ChatMessages {
  text-align: center;
  color: #9C9FAB;
  font-size: .8rem;
  margin-top: 15px;

  &_msg {
    display: flex;

    > div {
      padding: 10px;
      max-width: 300px !important;
      width: fit-content;
      display: flex;
      flex-direction: column;
      font-size: .9rem;

      img {
        object-fit: contain;
        max-width: 100%;
        height: auto;
        margin-bottom: 10px;
      }

      span {
        margin-top: 6px;
        font-size: .7rem;
        color: #9C9FAB;
      }
    }

    &.msg-in {

      > div {
        background-color: #DEE1F7;
        margin: 10px 0 0 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

      }
    }

    &.msg-out {
      justify-content: flex-end;

      > div {
        background-color: #fff;
        margin: 5px 20px 0 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;

        span {
          text-align: right;
        }

        svg {
          width: 16px;
          height: 16px;
          margin-left: 6px;
          fill: #8764da;
        }

      }
    }

    &.msg-new {
      margin-top: 1rem;
    }

    &.msg-urd {
      div {
        svg {
          fill: #9C9FAB;
        }
      }
    }

  }
}

