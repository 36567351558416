@import "../../vars";

.ActKSTopLineLittle {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: $height_1;
  min-height: $height_1;
  font-weight: 500;
  font-size: $fontSize_2;
  color: $color !important;

  > div:nth-child(2) {
    width: $width_3;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border: 1px solid $color;
    border-right: none;
  }

  > div:last-child {
    width: $width_4;
    min-width: $width_4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $color;
    border-bottom: 1px solid $color;
    border-left: 2px solid $color;
    border-right: 2px solid $color;
  }


}