@import "../../../scss/colors";

.LoaderContainer {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1200;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.3s;
}

.Text {
  font-size: 1.4rem;
  color: $main-4;
  letter-spacing: 0.3rem;
}

.Loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.Loader div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: $main-4;
  animation: Loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.Loader div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.Loader div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.Loader div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

@keyframes Loader {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
