@import "../../scss/colors";
@import "../../scss/backgrounds";

form.disabled {
  pointer-events: none;
}

.flex {
  display: flex;
  justify-content: space-between; 
}

.worker {
  &__downloads {
    display: flex;
    flex-wrap: wrap;
  }
}

.text {
  font-size: 18px;
  width: 30%;
  text-align: center;
}

