@import "../../../../../scss/colors";

.PersonalOrderItemService {

  min-width: 992px;

  table {
    width: 100%;
  }

  th {
    color: $main-1-2;
    font-size: .9rem;
    min-width: 150px;

    &:first-child {
      min-width: 220px;
    }

    &:nth-child(2) {
      min-width: 200px;
    }

    &:nth-child(3) {
      min-width: 100px;
    }
  }

  tbody {
    td {
      padding: 15px 10px 15px 0;
      font-weight: 500;
      font-size: .9rem;
    }
  }
}