@import "../../../../../../scss/colors";

.ServiceCalcBottom {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;

  position: relative;
  background: #fff;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.04);
  @media screen and (max-width: 575px) {
    padding: 12px;
    font-size: .8rem !important;
  }

  table {
    thead {
      background-color: #fff;
    }
  }

  &_leftBlock {
    flex: 1 0 25%;
    max-width: 25%;

    table {
      margin-bottom: 0 !important;
    }

    .TableBodyRow {
      overflow: hidden;
      & td:first-child > div {
      }
    }
  }

  &_rightBlock {
    flex: 1 0 75%;
    padding-bottom: 10px;

    table {
      margin-bottom: 0 !important;
    }

    th{
      div{
        justify-content: center;
      }
    }

    td{
      div{
        justify-content: center;
      }
    }

    &-heads {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      padding: 6px 18px;
      box-shadow: 0px 10px 20px rgba(5, 103, 208, 0.2);
      font-size: .8rem;

      span, strong {
        color: rgba(34, 34, 34, .5) !important;
      }

    }

    @media screen and(max-width: 767px) {
      flex: 1 0 60%;
    }
  }

  &_headRow {
    height: 66px;

    & th {
      padding: 0 !important;
    }
  }

  &_bodyRow {
    border-bottom: 1px solid $main-1-3;
    height: 70px;
    background: #fff;

    & td {
      padding: 0.5rem !important;

      div {
        @media screen and (max-width: 575px) {
          padding: 0 5px !important;
        }
      }
    }
  }

}

.ServiceCalcBottomTitle {
  padding: 20px;
  margin: 30px 0 0 0;
}


div.WidthToolTip {
  width: 100px;
  position: relative;
  text-align: left !important;
}
