@import "../../vars";

.ActKSTableColumn {
  color: $colorBlue;
  font-style: italic;

  & ~ .total {
    height: $height_2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $colorBlue;
    font-style: italic;
  }
}