.a {
  &__top-nav {
    background-color: #3F51B5;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    position: fixed;
    z-index: 999;

    &-left {
      display: flex;
      align-items: center;
    }

    &-title {
      color: #fff;
      font-size: 1.2rem;
      font-weight: 500;
      padding: 0 0 0 50px;
      letter-spacing: .08rem;
      text-transform: uppercase;
    }

    &-link {
      transition: .2s ease-in;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;

      > a {
        display: flex;
        align-items: center;

        &:hover {
          text-decoration: none;

        }
      }

      &:hover {
        opacity: .6;
      }

      svg {
        fill: #fff;
        width: 30px;
        height: 30px;
      }
    }

    &-logo {
      border-left: 5px solid #fff;
      border-right: 5px solid #fff;
      width: 180px;
      height: 50px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 70%;
    }
  }

  &__nav {
    position: fixed;
    top: 50px;
    width: 260px;
    min-height: calc(100% - 50px);
    border-right: 1px solid lightgray;
    background-color: rgba(255, 255, 255, .5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: .3s ease-in-out;

    &.compact {
      transform: translateX(-219px);
      transition: .3s ease-out;
    }

    &_arrow {
      height: 40px;
      position: relative;
      border-bottom: 1px solid lightgray;
    }

    &_compact {
      width: 35px;
      height: 35px;
      position: absolute;
      right: 2px;
      top: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 50%;
      transition: .3s ease-in-out;

      &:hover {
        background: rgba(0, 0, 0, .1);
      }

      svg {
        width: 30px;
        height: 30px;
      }
    }

    &-scroll {
      //max-height: 80vh;
      max-height: 90vh;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        height: 0px;
        width: 0px; /* width of vertical scrollbar */
        border: 1px solid #9DA2C4;
      }
    }

    &-list {
      margin: 10px 0 !important;
    }

    &_divider {
      margin: 10px 0;
      height: 1px;
      background-color: lightgrey;
    }

  }

  &__content {
    height: 100%;
    padding: 60px 15px 15px;
    margin-left: 260px;
    width: calc(100vw - 260px);
    min-width: 20%;
    transition: .3s ease-out;
    min-height: 100vh;

    &.compact {
      transform: translateX(-220px);
      width: calc(100% - 40px);
    }

    @media screen and (max-width: 776px) {
      padding: 60px 10px 15px 0;
    }
  }

  &__avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid lightgray;

    &_medium {
      width: 100px !important;
      height: 100px !important;
      margin-bottom: 10px;
    }

    &_large {
      width: 150px !important;
      height: 150px !important;
      margin-bottom: 15px;
    }
  }

  &__footer {
    display: flex;
    padding: 0 40px;
    align-items: center;
    height: 50px;
    background-color: #3F51B5;
    color: #fff;

    > div {
      width: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      border-left: 5px solid #fff;
      border-right: 5px solid #fff;
    }
  }

  &__service-list__item {
    width: 100%;
    margin-right: 1rem;
  }



}



