.Feedback {
  margin: 70px 0 50px;
  padding: 0 0 150px;
  position: relative;
  @media screen and (max-width: 575px) {
    margin: 70px 0 100px;
    padding: 0 0 30px;
  }
  @media (max-width: 511px) {
    margin: 70px 0 250px;
  }

  &__title {
    font-weight: bold;
    font-size: 25px;
    line-height: 44px;
    text-align: center;
    color: #000000;
    @media (max-width: 575px) {
      font-size: 14px;
      line-height: 28px;
      margin-bottom: -20px;
    }

  }
  &__button {
    width: 228px;
    height: 44px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    @media (max-width: 575px) {
      font-size: 12px;
      height: 37px;
    }
  }
}