@import "../vars";

.ActKSTopInfo {
  display: flex;
  justify-content: flex-end;
  color: $color;
  font-weight: 500;
  font-size: $fontSize_2;
  margin: 10px 0 0;

  &_item {
    width: $width_5;
    display: flex;
    border: 1px solid $color;

    &.left {
      > div {
        > div:first-child {
          height: $height_3;
        }

        > div:last-child {
          height: $height_1;
        }
      }
    }

    &.right {
      margin: 0 10% 0 50px;
      flex-direction: column;

      > div:first-child {
        height: $height_1;
      }

      > div:last-child {
        > div {
          > div {
            height: $height_1;
          }
        }
      }
    }
  }

}