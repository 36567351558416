.ServiceCalc {
  &__subwork-list {
    text-align: left;
    margin-top: 10px;

    li {
      list-style: decimal;
      color: #949494;
    }
  }

  &_container {
    position: relative;
    background: #fff;
    @media screen and (max-width: 575px) {
      padding: 12px;
      font-size: .8rem !important;
    }
  }
}