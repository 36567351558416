@import "../ActKSComponents/vars";

.ActKS {
  width: $actWidth;
  min-width: $actWidth;
  height: $actHeight;
  min-height: $actHeight;

  padding: $actPadding;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fff;
}
