.ChatCurrentImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 50px;
  left: 0;
  background-color: rgba(255, 255, 255, .7);
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    justify-content: center;
  }

  img {
    max-width: 50%;
    max-height: 50%;
    object-fit: cover;
    border: 10px solid #fff;
    border-radius: 5px;
    filter: drop-shadow(0 0 15px rgba(0, 0, 0, .3));
    margin-right: 15px;
  }

  &_close {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50%;
    height: 34px;
    width: 34px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      fill: rgba(33, 33, 33, .5);
      transition: .3s ease-in-out;
    }

    &:hover {
      svg {
        fill: rgba(33, 33, 33, .8);
      }
    }

  }
}