.Estimate {
  display: flex;
  flex-direction: column;
  min-height: inherit;
  justify-content: space-between;
  border-radius: 20px;


  &__categories {
    display: flex;
    flex-wrap: wrap;

    &-item {
      width: 162px;
      padding: 40px 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      &:hover,
      &.active {
        * {
          color: #7B53DA;
        }

        .Estimate__categories-item-name-with-arrow:after {
          opacity: 1;
        }
      }

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        box-shadow: 0 13px 12px -7px rgba(0, 54, 112, 0.16);
        border-radius: 20px;
      }

      &-name {
        text-align: center;
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        transition: .25s ease-in;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        &-with-arrow {
          display: flex;
          flex-wrap: nowrap;
          position: relative;
          transition: .25s ease-in;
          padding: 0 30px;

          &:after {
            content: '';
            display: block;
            background: url('../../../../../../../img/arrow-right.png');
            background-repeat: no-repeat;
            width: 20px;
            height: 16px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            opacity: 0;
            transition: .25s ease-in;
          }
        }
      }
    }
  }


  &-animate-service-item {
    border: 1px solid #c9d6e1;
    border-radius: 20px;
    transition: .7s ease-out;
    min-height: 40px;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    -ms-flex-align: center;
    align-items: center;
    background: #7b53da;
    color: #fff;
    padding: 5px 35px;
    margin-bottom: 5px;
    &.animate {
      transform: translate(650px, -50px);
      opacity: 0;
    }

    &-wrapper {
      position: fixed;
      right: 150px;
      bottom: 50%;
      transform: translateY(50%);
      pointer-events: none;
    }
  }
}