.ChatLoader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, .5);

  svg {
    margin: auto;
    background: none;
    display: block;
    shape-rendering: auto;
    width: 100px;
    height: 100px;
  }

}