.SliderHomePage {
  position: relative;
  margin-bottom: 30px;

  img {
    height: 100%;
    object-fit: cover;
    object-position: top right;
    width: 100%;
  }

  &__sliders {
    padding-top: 24px;
  }

  &__slide {
    height: 23.5rem!important;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &__info {
    border-radius: 14px;
    position: absolute;
    width: 70%;
    height: 108%;
    right: 0;
    top: 0;
    z-index: 1;
    padding: 63px 0 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.2));
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    
    @media screen and (max-width: 767px) {
      position: relative;
      height: auto;
      padding: 20px 10px;
      left: 0;
      top: 0;
      width: 100%;
      margin-bottom: -30px;
    }
  }

  &__address {
    display: flex;
    align-items: center;

    @media (max-width: 991px) {
      flex-direction: column-reverse;
    }

    &-left {
      display: flex;
      align-items: center;
    }

    &-icon {
      width: 2.85rem;
      height: 2.85rem;
      min-width: 2.85rem;
      min-height: 2.85rem;
      border: 1px dashed #fff;
      border-radius: 50%;
      display: block;
      position: relative;
      margin-right: 10px;

      svg {
        width: 1.28rem;
        height: 1.42rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: #fff;

        use {
          fill: #fff;
        }
      }
    }

    &-text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 0.85rem;
      text-transform: uppercase;
      color: #DEE7EF;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 3rem;
    color: #71E200;
    margin-bottom: 20px;
  }

  &__heading {
    font-size: 2.1rem;
    font-weight: 700;
    color: #fff;
    line-height: 145%;
    padding: 20px 0 0;
    text-align: center;
    margin-bottom: 60px;
    @media screen and (max-width: 1300px) {
      font-size: 1.6rem;
    }
    @media (max-width: 575px) {
      margin-bottom: 20px;
    }
    &-top {
      color: #fff;
    }
  }
  &__button {
    width: 100%;
    font-size: 1rem;
    &-wrapper {
      max-width: 200px;
      min-width: 200px;
      width: 100%;
      margin-right: 45px;
  
      @media (max-width: 991px) {
        margin-right: 0;
        margin-top: 15px;
      }
      @media (max-width: 575px) {
        max-width: 150px;
      }
    }
  }

  .hiddenSpan {
    opacity: 0;
    transform: translateY(-20px);
    transition: all 1s;
    display: inline-block;

    &.active {
      transform: none;
      opacity: 1;
    }
  }
}