.Header {
  padding: 10px 15px;
  background: #fff;
  @media screen and(max-width: 575px) {
    padding: 10px 15px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
  }

  &__logo {
    padding: 0 15px 0 0;

    img {
      width: 120px;
      height: auto;
    }

    @media screen and(max-width: 575px) and (orientation: portrait) {
      display: flex;
      align-items: flex-start;
      img {
        width: 134px;
        height: auto;
      }
    }
  }

  &__nav {
    height: inherit;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;


    &-list {
      z-index: 100;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0 10px;
      height: inherit;
      width: 100%;

      &-mobile {
        flex-direction: column;
        align-items: flex-end;

        li {
          margin-top: 20px;

          * {
            font-size: 20px;
          }
        }
      }

      & > li {
        height: 100%;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0 15px;
        font-size: 1rem;
      }
    }

    &-link {
      cursor: pointer;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: #000;
      transition: .25s ease-in;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        transition: .3s ease-out;
        fill: #C9D6E1;
      }

      &:hover {
        color: #5D20C1;
        text-decoration: none;

        svg {
          fill: #5D20C1;
        }
      }
    }

  }

  &__phone {
    display: flex;
    align-items: center;
    padding: 0 30px;
    &-mobile {
      color: #000;
      font-size: 13px;
      font-weight: 500;
      display: block;
      margin-left: 5px;
    }
  }

  &__personal {
    display: flex;
    align-items: center;

    &-exit {
      margin: 0 0 0 1rem;
    }
  }

  &__drawer {
    width: 100%;
    min-height: 110vh;
    position: fixed;
    background-color: #fff;
    z-index: 100;
    transition: 0.3s ease;
    transform: translateX(-100%);
    top: 0;
    left: 0;

    &[data-open="true"] {
      transform: none;
    }

    &-container {
      min-height: 100vh;
      padding: 35% 0 0 0;

      nav {
        width: 100%;
      }

      .Header__phone {
        display: flex;
        justify-content: flex-end;
        margin: 25px 0 0;
        font-size: 20px;
        padding: 0 25px;
      }

      .Header__personal {
        flex-direction: column;
        align-items: flex-end;
        margin: 50px 25px 0 0;
        a {
          margin: 0 0 15px;
        }

        * {
          font-size: 20px;
        }
      }
    }

  }
}


