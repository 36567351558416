.estimate-process-steps {
  display: flex;
  align-items: center;
  padding: 0 68px;
  margin-bottom: 50px;

  &-step {
    border-bottom: 1px solid #C9D6E1;
    padding: 7px;
    position: relative;
    flex: 1 0 auto;

    &:first-child {
      &:before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        left: -5px;
        bottom: -5px;
        border-radius: 50%;
        background: #C9D6E1;
        position: absolute;
        //background: #88f61b;
      }
    }

    &_active, &_done {
      &:after {
        position: absolute;
        content: '';
        display: block;
        width: 50%;
        height: 1px;
        background: #88f61b;
        bottom: -1px;
        left: 0;
      }

      &:first-child {
        &:before {
          background: #88f61b;
        }
      }
    }

    &_done {
      &:after {
        width: 100%;
      }
    }

    &-title {
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      color: #8594A2;
      width: 200px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-success {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background: #FFFFFF;
      border: 1px dashed #C9D6E1;
      position: absolute;
      right: -15px;
      bottom: -15px;
      z-index: 1;

      svg {
        transition: .25s ease-in;
      }

      &_done {
        border: 1px dashed #88f61b;

        svg * {
          stroke: #88f61b;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    height: 110px;
    overflow-x: scroll;
  }

  @media (max-width: 991px) {
    padding: 0 9px;
  }

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    overflow: visible;

    &-step {
      border-bottom: none;
      border-left: 1px solid #C9D6E1;
      &::before {
        left: 0;
        bottom: auto;
        top: 0;
      }

      &-title {
        font-size: 8px;
        height: auto;
        width: auto;
      }
      &-success {
        bottom: 0;
        right: auto;
        left: 0;
        transform: translate(-50%, 50%);
        width: 14px;
        height: 14px;
      }
      &_active,
      &_done {
        border-color: #88f61b;
        > div {
          color: #88f61b;

        }
      }
      &_active:after,
      &_done:after {
        width: 0;
        height: 0;
      }
    }
  }
}