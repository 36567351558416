@import "../../../scss/colors";

.OrderStatus {
  //&_status {
  //  flex: 1 0 auto;


  &_status {
    div:first-child {
      color: #E2E70F;
      font-size: .9rem;
      line-height: .9rem;
      font-weight: 500;
      position: relative;
      padding-left: 20px;

      &:before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: #E2E70F;
        //background-color: $main-4;
      }
    }


    &-NW, &-1 {
      div:first-child {
        color: #88F61B;

        &:before {
          background-color: #88F61B;
        }
      }
    }

    &-IP, &-3 {
      div:first-child {
        color: $main-4;

        &:before {
          background-color: $main-4;
        }
      }
    }

    &-IM, &-8 {
      div:first-child {
        color: #FEBC42;

        &:before {
          background-color: #FEBC42;
        }
      }
    }

    &-R {
      div:first-child {
        color: #C60C30;

        &:before {
          background-color: #C60C30;
        }
      }
    }

  }


}

//}