// MAIN
$main-1: #F3F0FC;
$main-1-1: #F1F2FA;
$main-1-2: #CDC8D7;
$main-1-3: #D7DAED;
$main-1-4: #E2E4F1;

//$main-2: #E7E1F8;
$main-2: #c4b6e4;
$main-2-1: #9DA2C5;
$main-3: #D4C8F2;
$main-4: #8764DA;
$main-4-05: rgba(135, 100, 218, .5);
$main-4-07: rgba(135, 100, 218, .7);
$main-5: #57297C;


$black-font: #292929;
$dark-font: #414141;

$green-1: #88f61b;
$green-2-05: rgba(136, 246, 27, .5);

// ADMIN
$blue: #3D6AAE;
$gray: #B4B4B4;
$darkBlue: #3F51B5;