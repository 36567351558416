.Footer {
  height: 560px;
  background: #7B53DA;
  border-radius: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 100px 40px 35px;
  @media (max-width: 1348px) {
    height: 678px;
  }
  @media(max-width: 991px) {
    height: 873px;
    padding: 0 50px 40px;
  }
  @media(max-width: 674px) {
    height: 900px;
  }
  @media screen and (max-width: 575px) {
    height: 850px;
    padding: 0 25px 25px 25px;
  }

  &__light {
    height: 250px;
    border-radius: 14px;
    @media screen and (max-width: 575px) {
      height: 500px;
    }
  }

  &__tablo {
    border: 1px solid #DEE7EF;
    box-shadow: 17px 43px 30px -18px rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    position: absolute;
    width: 66%;
    left: 50%;
    top: 0;
    overflow: hidden;
    transform: translate(-50%, -100px);

    @media  (max-width: 1348px) {
      transform: translate(-50%, -32%);
    }
    
    @media (max-width: 767px) {
      width: 95%;
    }
    
    @media (max-width: 511px) {
      transform: translate(-50%, -57%);
    }
  }

  &__socials {
    display: flex;
    margin: 20px 0 10px;
    padding: 0;
    align-items: center;

    &-item {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  &__nav {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @media screen and (max-width: 575px) {
      flex-wrap: wrap;
      position: static;
      height: auto;
    }

    &-logo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      @media screen and (max-width: 575px) {
        height: 80px;
        margin: 25px auto 0;
      }

      img {
        width: 134px;
        height: auto;

        @media (max-width: 400px) {
          width: 80%;
          margin-bottom: 20px;
        }
      }
    }

    &-policy {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #FFFFFF;

      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }

    &-links {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100px;
      //padding: 0 0 0 60px;
      @media screen and (max-width: 575px) {
        height: auto;
        margin: 0 0 15px 10px;
      }
      &-item, a {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        @media screen and (max-width: 575px) {
          line-height: 30px;
          font-size: 14px;
        }

        &:not(.no-hover):hover {
          cursor: pointer;
          text-decoration: underline;

          svg, svg * {
            fill: #fff;
          }
        }
      }
    }
    @media(max-width: 991px) {
      flex-wrap: wrap;
      &-logo {
        height: auto;
        width: 100%;
      }
      &-menu {
        flex-direction: row;
        justify-content: flex-start;
        & > div:not(:first-child) {
          margin-left: 10px;
        }
      }
      &-links {
        width: 100%;
        height: auto;
        margin: 15px 0;
        &-contacts {
          flex-direction: row;
          display: flex;
          & > div:not(:first-child) {
            margin-left: 15px;
          }
        }
        &-info > div:not(:first-child) {
          margin-top: 10px;
        }
      }
    }
    @media (max-width: 435px) {
      &-links {
        &-contacts {
          flex-direction: column;
          & > div:not(:first-child) {
            margin-left: 0;
          }
        }
      }
    }
  }

  &__downloads {
    &-list {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
    &-item {
      margin: 0 20px;
    }
    @media (max-width: 575px) {
      &-list {
        flex-direction: column;
      }
      &-item {
        margin: 20px 0;
      }
    }
    @media (max-width: 435px) {
      &-item {
        margin: 10px 0;
      }
    }
  }

  &__button {
    transition: all .3s;
    color: #7B53DA;
    border: 1px solid #7B53DA;
    background-color: #fff;
    border-radius: 39px;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    padding: 10px 22px;

    &:hover {
      color: #fff;
      border: 1px solid #71E200;
      background-color: #71E200;
    }
  }
}