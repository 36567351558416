.ChatList {

  width: 100%;
  display: flex;
  padding: 10px 0;
  flex-direction: column;

  > li {
    display: flex;
    height: 70px;
    width: 100%;
    transition: .3s ease-in-out;
    padding: .75rem;
    align-items: center;
    border-top: 1px solid #F1F2FA;

    &:hover {
      cursor: pointer;
      background-color: #ECEEFB;
    }

    &.active {
      background-color: #8764DA;

      .ChatList_content {
        * {
          color: #fff;
        }
      }
    }

  }

  &_profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 1rem 0 0;
  }

  &_preview {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    svg {
      width: 18px;
      height: 18px;
      fill: #8764DA;
      margin-right: 6px;
    }

    &.active {
      svg {
        fill: #fff
      }
    }

  }

  &_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 0;

    &-name {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3px;

      strong {
        font-size: .9rem;
      }

      &-date {
        display: flex;
        flex-direction: column;
        color: #9C9FAB;

        span {
          font-size: .8rem;
        }
      }
    }

    &-lastMessage {
      display: flex;
      //justify-content: space-between;
      align-items: center;
      color: #9C9FAB;
      font-size: .8rem;

      &-count {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #8764DA;
        height: 20px;
        width: 20px;
        min-width: 20px;
        min-height: 20px;
        border-radius: 50%;
        color: #fff;

        &.active {
          background-color: #fff;
          color: #8764DA !important;
        }
      }
    }
  }
}
