@import "../../../../../../scss/colors";

.ServiceConfirmTypeTabs {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 15px 0 0;

  &_tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 15px;
    font-size: 1rem;
    cursor: pointer;

    border:1px solid $main-1;
    background: $main-4;
    color: #fff;
    transition: .3s ease-out;
    width: 145px;

    &.active {
      background: #fff;
      color: #000;
      top: 0;

      strong {
        color: #000;
      }
    }


  }

  & > div:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

  }

  & > div:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }


}



