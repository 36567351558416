.CountSlider {
  margin: 80px 0 0;
  position: relative;
  @media screen and (max-width: 575px) {
    margin: 50px 0 0;
  }
  @media (max-width: 575px) {
    margin-top: 28px;
  }

  &__item {
    border-radius: 20px;
    height: 164px;
    margin: 0 0 20px 0;
    padding: 38px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: #7B53DA;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../img/maskgroup.png");
    @media screen and (max-width: 575px) {
      height: 100px;
      padding: 5px 10px;
      margin: 0 0 10px 0;
    }

    &-title {
      font-size: 30px;
      line-height: 22px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #FFFFFF;
      background: #5D20C1;
      border-radius: 20px;
      height: 88px;
      width: 570px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 575px) {
        font-size: 18px;
        height: 60px;
      }
    }

    &-num {
      background: #5D20C1;
      border-radius: 20px;
      margin: 0 0 0 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
      font-size: 50px;
      line-height: 44px;
      font-weight: bold;
      width: 70px;
      height: 88px;
      text-align: center;
      @media screen and (max-width: 575px) {
        font-size: 22px;
        width: 50px;
        height: 60px;
        margin: 0 0 0 10px;
        padding: 0 5px;
      }
    }
  }

  &__dots {
    width: 200px;
    margin: 0 auto;
    background: rebeccapurple;
  }

  &__arrow {
    position: absolute;
    bottom: -5px;
    cursor: pointer;
    width: 20px;

    &-previous {
      left: 50%;
      transform: translateX(-100px);
    }

    &-next {
      left: 50%;
      transform: translateX(80px);
    }
  }

}