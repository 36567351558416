@import "../../../../../../scss/colors";

.ServiceCalcRow {
  height: 60px;
  //background-color: $main-2;
  border-bottom: 1px solid $main-1-3;

  svg {
    path {
      transition: .3s ease-in;
    }

    &:hover {
      cursor: pointer;

      path {
        fill: rgba(0, 0, 0, .5);
        transition: .3s ease-in;
      }
    }
  }

  td {
    padding: 0 0.5rem;
  }

}

.WorkerCount {
  width: 50px;
  height: 100%;
  background: #fff;
  border: none;
  font-weight: bold;
}