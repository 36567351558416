.ChatImageUploader {
  &_photo {
    //background-color: rebeccapurple;
    > div {
      padding: 0;
      background: transparent;

      //height: 26px;
      svg {
        width: 30px;
        height: 28px;
        margin-right: 15px;
        cursor: pointer;
        transition: .3s ease-in-out;
       border-radius: 1px;
        fill: #C3B1EC;
        background: transparent;
        box-shadow: 0px 10px 20px rgba(135, 100, 218, 0.4);

        &:hover {
          fill: #8764da;
        }
      }
    }
  }
}