.ChatBtnDown {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: 10px;
  bottom: 60px;

  svg {
    width: 20px;
    height: 20px;
    fill: #000;
  }
}