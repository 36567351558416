.AdminOrderSettings {

  &__title {
    width: 75%;
    background: #fff;
    padding: 5px 15px;
    border-right: 2px solid #3F51B5;

    input {
      padding: 7px 15px 5px;
    }
  }

  &__percent {
    padding: 5px 15px;
    background: #fff;
    flex: 1 0 auto;

    input {
      padding: 7px 15px 5px;
    }
  }
}