.TooltipBlueWhite{
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  //background-color: #3D6AAE;
  //top: 0;
  //right: 5px;
  padding-top: 1px;
  z-index: 10;
  text-align: center;
  cursor: help;
}