@import "../../vars";

.ActKSTableBody {
  display: flex;
  width: 100%;
  margin: 10px 0;
  font-size: $fontSize_2;
  font-weight: 500;
  color: $color;
  line-height: $fontSize_2;

  &_column {
    .name {
      height: $height_3;
      min-height: $height_3;
      text-align: center;
      padding: .2rem .5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &.material{
        height: $height_7;
        min-height: $height_7;
      }
    }

    .num {
      height: $height_1;
      min-height: $height_1;
      text-align: center;
      padding: 0 .5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &.material {
        height: $height_6;
        min-height: $height_6;
      }

      &.material-num {
        height: $height_3;
        min-height: $height_3;
      }
    }
  }
}