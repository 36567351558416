@import "../../../../scss/colors";

.AdminOrderList {
  &__finance {
    &-tabs {
      min-height: 30px !important;

      button {
        min-height: 30px;
      }
    }

    &-links {
      min-height: 30px !important;

      > div > .MuiTabs-indicator {
        display: none;
      }
    }

    &-link {
      border-bottom: 2px solid transparent;
      opacity: 1 !important;

      &:hover {
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          height: 2px;
          width: 100%;
          transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          background-color: #8764DA !important;
        }

      }
    }


    &-li {
      height: 50px;
    }

    &-pays-li {
      min-height: 38px;
    }

    &-accordion-head {
      padding: 0;
      min-height: 35px !important;
      height: 38px;

      .MuiAccordionSummary-expandIcon {
        svg {
          fill: #000 !important;
        }
      }
    }

    &-head {
      height: 60px;
    }

    &-item {
      height: 40px;
    }

  }

  &__status {
    display: flex;
    align-items: center;
    color: #88F61B;

    &-indicator {
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background: #88F61B;
      margin-right: 8px;
    }

    &.OP {
      color: #FFA011;

      .AdminOrderList__status-indicator {
        background: #FFA011;
      }
    }

  }

  &__modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    background: rgba(0, 0, 0, .3);

    &.hide {
      display: none;
    }

    &-close {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      transition: background 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &:hover {
        background: rgba(0, 0, 0, .05);
      }
    }

    form {
      width: 400px;
      position: relative;
    }
  }

  &__detail-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 99;
    padding: 80px 1rem 1rem;

    &-row {
      transition: 0.3s ease-in;

    }

    &-box {
      background: rgba(0, 0, 0, 0.03);
      margin-bottom: 5px !important;
    }

    &-link {
      cursor: pointer;
      transition: 0.3s ease-in;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }

    &-close {
      position: absolute;
      right: 50px;
      cursor: pointer;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      top: 68px;

      &:hover {
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      }
    }

    &-scroll {
      overflow-y: scroll;
      height: 80vh;
    }
  }

  &__cell {

    &-row {
      //padding-top: 0 !important;
      //padding-bottom: 0 !important;
      padding: .5rem !important;
    }

    width: 5%;

    * {
      line-height: 130% !important;
    }

    &.large {
      width: 15%;
    }
  }

  &__gallery {
    > div > img {
      object-fit: cover;
      height: 200px;
      width: 200px;
      margin: 1rem;
    }
  }

  &__task-status {
    padding: 0 10px;
    background: #62C301;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: fit-content;
    min-width: 68px;

    &.WK {
      background: #8764DA;
    }

    &.WT {
      background: #FFA011;
    }

    &.CL {
      background: #C60C30;
    }
  }

  &__task-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 14px;
      fill: #757575;
    }
  }

  &__table-head {
    background: $darkBlue;

    * {
      color: #fff;
    }

    &-ws {
      //white-space: nowrap;
      min-width: 80px;
    }

    &-delete {
      margin-left: 5px;
      cursor: pointer;
    }

    &_50 {
      min-width: 50px;
      width: 50px;
    }

    &_75 {
      min-width: 75px;
      width: 75px;
    }

    &_100 {
      min-width: 100px;
      width: 100px;
    }

    &_150 {
      min-width: 150px;
      width: 150px;
    }

    &_200 {
      min-width: 200px;
      width: 200px;
    }
  }

  &__table-scroll {
    min-width: 1200px;
    overflow-x: auto;
  }

  &__row {
    cursor: pointer;
    transition: 0.5s ease-in;

    &:hover {
      background: rgba(0, 0, 0, .025);
    }

    &-left {
      vertical-align: baseline !important;
      padding-top: 10px !important;
    }
  }

  &__hidden {
    opacity: 0.5;

    td, tr {
      max-height: 5px;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      line-height: 1px;
      opacity: 0;
      //font-size: 0!important;
    }
  }

  &__select {
    height: 30px;
    border-radius: 3px;
    padding-left: 5px;

    &.classifier__select {
      margin: 30px 0 0;
      width: 100%;
    }
  }

  &__active-date {
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 86%;
      height: 12px;
      background-color: #fff;
      -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
      clip-path: polygon(0 0, 50% 100%, 100% 0);
      bottom: -11.5px;
    }
  }

}