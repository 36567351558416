.WorkerAuthForm {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  //background-color: rgba(0, 0, 0, .75);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 100;

  &_tabs {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    > div {
      height: 40px;
      width: 49%;
      background-color: #dedede;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: 1px;
      z-index: 0;
      transition: .2s ease-in-out;

      &.active {
        background-color: #fff;
      }
    }
  }

  &_name {
    color: #3F51B5;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  &_container {
    width: 420px;
    min-height: 300px;
    max-width: 90%;
    position: absolute;
    left: 50%;
    top: 50%;
    padding-bottom: 20px;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;


    form {
      flex: 1 0 auto;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      position: relative;
      z-index: 1;

      label {
        font-size: .8rem;
      }

      > div {
        width: 80%;
        //margin-top: 30px;
      }
    }
  }

  .danger {
    border-color: #dc3545;
  }

}


.ButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.WrongPass {
  text-align: center;
  font-size: .7rem;
  color: #dc3545;
  margin-top: 5px;
}



