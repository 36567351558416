@import "../../../../../../scss/colors";
.ServiceCalcTableHead{
  background-color: #fff;
  transition: .2s ease-in-out;
  //border-bottom: 10px solid $main-1;
  tr{
    height: 66px;
  }
  tr th{
    color: $main-1-2;
    vertical-align: middle;
    padding: 0 0.5rem;
    strong, div {
      color: currentColor;
    }
  }
}
