@import "colors";

.MinusPlus {
  transition: .2s ease-in-out;
  * {
    fill: $black-font!important;
  }
}

.Delete {
  transition: .2s ease-in-out;
  * {
    fill: $main-3!important;
  }
  &:hover{
    * {
      fill: $main-4!important;
    }
  }
}
