
.ham-btn {
  position: absolute;
  width: 80px;
  height: 80px;
  top: -5px;
  right: 0;
  z-index: 999;
  cursor: pointer;

}

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hamRotate.active {
  transform: rotate(45deg);
}

.hamRotate180.active {
  transform: rotate(180deg);
}

.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #57297C;
  stroke-width: 5.5;
  strokeLinecap: round;
}

.ham8 .top {
  stroke-dasharray: 40 160;
}

.ham8 .middle {
  stroke-dasharray: 40 142;
  transform-origin: 50%;
  transition: transform 400ms;
}

.ham8 .bottom {
  stroke-dasharray: 40 85;
  transform-origin: 50%;
  transition: transform 400ms, stroke-dashoffset 400ms;
}

.ham8.active .top {
  stroke-dashoffset: -64px;
}

.ham8.active .middle {
  //stroke-dashoffset: -20px;
  transform: rotate(90deg);
}

.ham8.active .bottom {
  stroke-dashoffset: -64px;
}
