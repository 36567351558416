.Th {
  position: relative;
  height: inherit;
  padding:0.75rem 0!important;
}

.ThItem {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: .9rem !important;
  justify-content: flex-start;
  font-weight: normal;

  @media screen and (max-width: 575px) {
    font-size: .7rem!important;
  }
}