.AdminGoodsRight{
  position: relative;
  &__empty{
    width: 300px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    font-weight: 500;
  }
}