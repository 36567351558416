.main {
  max-width: 1150px;
  width: 100%;
  margin: 50px auto 0;

  @media(max-width: 991px) {
    padding: 0 15px;
  }

	&Title {
    max-width: 898px;
    width: 100%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #303030;
    margin: 40px auto 20px;
	}
	&Wrapper {
    
	}
	&TextBlock {
    
  }
	&Slider {
    &Block {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media (max-width: 991px) {
        justify-content: center;
        margin-top: 20px;
      }
    }
    &Wrapper {
      width: 544px;
      height: 340px;
      background: #FFFFFF;
      box-sizing: border-box;
      box-shadow: 0px 36px 35px -33px rgba(0, 34, 86, 0.2);
      border-radius: 10px;
      transition: all .7s;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top: 20px;

      @media(min-width: 992px) {
        &:hover {
          transform: translateY(-15px);
          box-shadow: 0px 56px 35px -33px rgba(0, 34, 86, 0.2);
        }
      }
    }
    &Head {
      font-size: 18px;
      text-align: center;
    }
    &Content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}

.textBlock {
  &Item {
    &:not(:first-child) {
      margin-top: 50px;
    }
  }
  &Title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #303030;
    margin-bottom: 15px;
  }
  &Text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
}

.list {
  padding: 0;
   
  li {
    margin: 6px 0;
  }
}

.categories {
	&Wrapper {
		position: relative;
		width: 300px;
    > div:first-child {
      z-index: 2;
    }
	}
	&Arrows {
		position: absolute;
		bottom: 50%;
		left: 50%;
		width: 400px;
    height: 100%;
		transform: translate(-50%, 50%);
		display: flex;
		align-items: center;
		justify-content: space-between;

    @media (max-width: 450px) {
      width: 300px;
    }
	}
	&Arrow {
		cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    width: 50px;
    align-items: center;
    justify-content: center;
    transition: all .6s;
    background-color: rgba(0, 0, 0, 0);
    &:hover {
      background-color: rgba(0, 0, 0, .05);
    }
	}
	&Item {
		display: flex;
		flex-direction: column;
		align-items: center;
    cursor: pointer;
	}
	&Image {
		width: 200px;
		height: 200px;
		object-fit: cover;
		box-shadow: 0 13px 12px -7px rgba(0, 54, 112, 0.16);
		border-radius: 20px;
		margin-bottom: 10px;
	}
	&Name {
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		text-align: center;
		color: #303030;
    margin-top: 10px;
	}
}

@media (max-width: 575px) {
  .main {
    margin-top: 15px;
    &Title {
      font-size: 12px;
      margin-bottom: 18px;
      line-height: 20px;
    }
  }
  .textBlock {
    &Item:not(:first-child) {
      margin-top: 10px;
    }
    &Title {
      font-size: 14px;
      margin-bottom: 5px;
    }
    &Text {
      font-size: 12px;
      line-height: 18px;
    }
  }
}