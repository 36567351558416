@import "../../scss/colors";

.Admin {
  min-width: 1200px;
  //background: linear-gradient(to left, #c2cade, #ffffff);
  padding: 70px 30px 50px;

  &_input {
    input {
      padding-left: .5rem;
    }
  }

  &_select {
    padding: 6px 10px !important;
    display: flex !important;

    &-list {
      > div {
        padding: 10px;
      }
    }
  }

  &_counter {
    height: 100%;
    background-color: #E2DDF0;
  }

  &_category-acc-header {


    .MuiAccordionSummary-expandIcon {
      svg {
        fill: #fff !important;
      }
    }
  }

  &_cell {
    padding: 0 10px !important;

    input {
      padding: 10px 0 10px 10%;
    }
  }

  &_service-list {
    input {
      padding: 5px 15px;
      font-weight: 400;
      border-radius: 0;
    }
  }

  &_category-list {
    input {
      font-weight: 400;
      color: #ffc107;
      padding: 5px 15px;

      & ~ fieldset {
        border-radius: 0 !important;
      }

      &:focus ~ fieldset {
        transition: .2s ease-in;
        border-color: #ffc107 !important;
      }
    }

    &-num {
      width: 26px;
    }

    &-img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 2px;

      &-edit {
        border: 1px solid #ffc107;
        margin: 0 10px 0 34px;
      }
    }
  }

  &_subCategory-list {
    input {
      font-weight: 400;
      color: #000;
      padding: 5px 15px;

      & ~ fieldset {
        border-radius: 0 !important;
      }

      &:focus ~ fieldset {
        transition: .2s ease-in;
        border-color: #ffc107 !important;
      }
    }
  }

  &_categories {
    background: linear-gradient(to left, #3f4c6b, #3f51b5);

    .MuiAccordionSummary-expandIcon svg {
      fill: #fff !important;
    }
  }

  &_subcategory {
    background: linear-gradient(to left, #3f4c6b 1%, #fff 20%);

    .MuiAccordionSummary-expandIcon svg {
      fill: #fff !important;
    }

  }

  &_subWorks {
    background-color: #fff;
    //background: linear-gradient(to left, #404d6c, #ffffff 50%);
  }

  &_works {
    background: linear-gradient(to left, #4b5773 5%, #fff 40%);
  }

  &_list {
    width: 100%;
    margin-top: 15px !important;

    &-sub {
      width: 100%;
    }
  }

  &_row {
    height: 50px;
    display: flex !important;
    align-items: baseline !important;
    //align-items: flex-start!important;
  }

  &_head {
    height: 50px;

    &-item {
      //padding: 5px 0;
      span {
        //height: 1.2rem;
        align-items: center;
        display: flex;
        line-height: 12px;

        &:last-child {
          margin: 5px 0 0;
        }
      }
    }
  }

  &_btn-icon-list {
    * {
      fill: #000;
    }
  }

  &_btn-icon-create {
    * {
      fill: #fff;
    }
  }


  .listItem {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 10px;
      height: 10px;
      top: 50%;
      right: 0;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }

    &.enableItem {
      &:after {
        background-color: #62C301;
      }
    }

    &.disableItem {
      &:after {
        background-color: #f50057;
      }
    }

    &.editedItem {
      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 10px;
        height: 10px;
        top: calc(50% + 10px);
        right: 0;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        background-color: #FFA011;
      }

      &:after {
        top: calc(50% - 10px);
      }
    }
  }

  &_material-list {
    &-content {
      min-height: 40px;
    }

    li {
      padding: 2px 0;

      > div {
        background: linear-gradient(to right, #ffffff 80%, #577e9896);
        //background: linear-gradient(to left, #404d6c, #ffffff 20%);
      }
    }
  }

  &_material-list-item {
    &__input {
      //height: auto;
      > div {
        padding: 10px;
        height: 50px;
        background: #fff;
        box-shadow: 0 1px 2px rgba(0, 0, 0, .2) inset, 0 -1px 0 rgba(0, 0, 0, .05) inset;

        textarea {
        }
      }

      input {
        padding: 10px;
      }
    }

    &__select {

      > div {
        height: 50px;
        background: #fff;
        box-shadow: 0 1px 2px rgba(0, 0, 0, .2) inset, 0 -1px 0 rgba(0, 0, 0, .05) inset;
      }
    }

    &__name {
      &:first-letter {
        font-weight: 600;
        font-size: 110%;
      }
    }

    .goods-list-item {

      &__content {
        border-bottom: 1px solid #DDDDDD;
        border-top: 1px solid #DDDDDD;
        transition: .3s ease-in-out;

        &:hover {
          border-bottom: 1px solid #3f51b5;
          border-top: 1px solid #3f51b5;
        }
      }

      &__svg {
        fill: #3f51b5;
        position: absolute;
        right: 0;
      }
    }

    &.goods-list-item {
      cursor: pointer;
      transition: .3s ease-in-out;

      &.Mui-selected, &.Mui-selected:hover {
        background-color: transparent;

        .goods-list-item__content {
          border-bottom: 1px solid #3f51b5;
          border-top: 1px solid #3f51b5;
        }
      }
    }


  }

  &__switch {
    padding: 0;
    margin: 0;

    &-label {
      font-size: .9rem !important;
      color: #FFA011;

      &_checked {
        color: #62C301;
      }
    }


    &-btn {
      .MuiSwitch-switchBase {
        color: #FFA011;
      }

      .MuiSwitch-track {
        background-color: #DADADA;
      }

      &_checked {
        .MuiSwitch-switchBase {
          color: #62C301 !important;
        }

        .MuiSwitch-track {
          background-color: #DADADA !important;
        }
      }
    }

    &-time {
      font-size: 0.9rem;
      position: absolute;
      bottom: -12px;
      left: 29px;
    }
  }

}

.date-select {
  margin-left: 15px !important;
  width: 150px;

  > div {
    > div {
      padding: 8px 15px;
    }
  }
}
