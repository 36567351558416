@import "../../../../../scss/colors";

.PersonalOrderItemTabsSmall {
  display: flex;
  justify-content: center;
  width: 100%;


  &_tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 15px;
    font-size: 1rem;
    cursor: pointer;
    //flex-grow: 1;
    //background-color: #fff;
    //filter: drop-shadow(0 -2px 5px rgba(0, 0, 0, .06));
    border:1px solid $main-1;
    background: $main-4;
    color: #fff;
    transition: .3s ease-out;
    width: 145px;

    //&.active {
    //  background: $main-4;
    //  color: #fff;
    //  //filter: drop-shadow(0 -5px 5px rgba(0, 0, 0, .04));
    //
    //  strong {
    //    color: #fff;
    //  }
    //}

    &.active {
      background: #fff;
      color: #000;
      top: 0;
      //filter: drop-shadow(0 -5px 5px rgba(0, 0, 0, .04));

      strong {
        color: #000;
      }
    }


  }

  & > div:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

  }

  & > div:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }


}



