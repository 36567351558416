.main {
	max-width: 1150px;
	width: 100%;
	margin: 0 auto;
	margin-top: 70px;
	padding: 0 15px;
	position: relative;
	z-index: 1;

	&Wrapper {
	}
	&LeftBlock {
		
	}
	&Title {
		margin-bottom: 25px;
	}
	&TitleNumber {
		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 28px;
		color: #7b53da;
	}
	&TitleText {
		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 28px;
		color: #303030;
	}
	&TextBlock {
		&:not(:last-child) {
			margin-bottom: 35px;
		}
	}
	&Subtitle {
		font-family: Montserrat;
		font-style: normal;
		font-weight: 900;
		font-size: 18px;
		line-height: 28px;
		color: #88f61b;
		margin-bottom: 15px;
		display: block;
	}
	&Paragraph {
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		color: #000000;
	}
	&List {
		padding: 0;
		margin: 25px 0;
	}
	&ListItem {
		padding-left: 34px;
		position: relative;

		&:not(:first-child) {
			margin-top: 15px;
		}
		&:before {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: #88f61b;
			position: absolute;
			left: 0;
			bottom: 50%;
			transform: translateY(50%);
			content: "";
		}
	}
	&Paragraph {
	}
	&RightBlock {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-start;
		@media (max-width: 991px) {
			align-items: center;
		}
	}
}

.calculator {
	&Head {
		font-size: 18px;
		margin-bottom: 10px;
		text-align: center;
	}
	&Wrapper {
		background: #ffffff;
		box-sizing: border-box;
		box-shadow: 0px 36px 35px -33px rgba(0, 34, 86, 0.2);
		transition: box-shadow 0.7s, transform 0.7s;
		border-radius: 10px;
		max-width: 544px;
		width: 100%;
		height: 440px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
	
		&:not(:first-child) {
			margin-top: 75px;
		}
	
		@media (min-width: 992px) {
			&:hover {
				box-shadow: 0px 56px 35px -33px rgba(0, 34, 86, 0.2);
				transform: translateY(-15px);
			}
		}
	
		@media (max-width: 991px) {
			margin-top: 20px;
		}
	
		&.builder {
		}
		&.materials {
		}
	}
}

.builder {
	&Calculator {
	}
}
.materials {
}

.slider {
	&Wrapper {
		position: relative;
		width: 300px;
		min-height: 126px;
		margin: 0 auto;
	}
	&Item {
	}
	&Name {
		width: 300px;
		text-align: center;
		margin: 0 auto;
	}
	&Arrows {
		position: absolute;
		bottom: 50%;
		left: 50%;
		width: 350px;
		transform: translate(-50%, 50%);
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media (max-width: 450px) {
			width: 290px;
		}
	}
	&Arrow {
		cursor: pointer;
	}
	&Arrow {
	}
}

.form {
	&Wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 15px;
		width: 100%;
	}
	&InputsWrapper {
		margin-bottom: 20px;
		width: 100%;
		padding: 0 7px;
	}
	&Label {
		margin: 0 auto 7px;
		align-self: flex-start;
	}
	&Input {
		padding-left: 16px;
		max-width: 369px;
		width: 100%;
		height: 38px;
		border: 1px solid #c9d6e1;
		border-radius: 6px;

		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 20px;
		color: #8594a2;
		margin-bottom: 10px;
	}
	&ResultWrapper {
		display: flex;
		align-items: center;
	}
	&ResultItem {
		display: grid;
		margin: 0 3px;
		place-content: center;
		width: 28px;
		height: 24px;
		background: #8764da;
		border-radius: 6px;
		font-weight: 500;
		font-size: 16px;
		line-height: 26px;
		color: #fff;
	}
}

.materials {
	&Wrapper {
		padding: 0 15px;
		overflow-y: hidden;
		height: 100%;
		text-align: center;
		max-width: 100%;
		overflow-x: auto;
		td {
			vertical-align: middle;
		}
	}
}

.tableWrapper {
	height: 100%;
	overflow-y: auto;
}

.control {
	&Wrapper {
		background: transparent;
		box-sizing: border-box;
		box-shadow: 0px 36px 35px -33px rgba(0, 34, 86, 0.2);
		transition: box-shadow 0.7s, transform 0.7s;
		border-radius: 10px;
		max-width: 544px;
		width: 100%;
		padding-bottom: 0px;
		display: flex;
		align-items: flex-start;
		justify-content: center;

		@media (min-width: 992px) {
			&:hover {
				box-shadow: 0px 56px 35px -33px rgba(0, 34, 86, 0.2);
				transform: translateY(-15px);
			}
		}

		img {
			max-width: 100%;
		}

		@media (max-width: 991px) {
			margin: 0 auto;
		}
	}
}

.alignStart {
	> div {
		align-items: flex-start;
		justify-content: flex-start;
	}
}

.loaderWrapper {
	height: 100%;
	display: grid;
	place-items: center;
}

.button {
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: #FFFFFF;
	width: 247px;
	height: 44px;
	margin: 50px auto 0;
}

@media (max-width: 575px) {
	.main {
		margin-top: 15px;
		&Title {
			margin-bottom: 6px;
		}
		&TextBlock:not(:last-child) {
			margin-bottom: 20px;
		}
		&TitleText {
			font-size: 14px;
			margin-bottom: 5px;
		}
		&Subtitle {
			font-size: 13px;
			margin-bottom: 5px;
		}
		&Paragraph {
			font-size: 12px;
			margin-bottom: 5px;
			line-height: 18px;
		}
		&List {
			margin: 7px 0;
		}
		&ListItem {
			font-size: 12px;
			line-height: 18px;
			font-weight: 400;
			padding-left: 28px;
			&:not(:first-child) {
				margin-top: 5px;
			}
			&:before {
				width: 7px;
				height: 7px;
			}
		}
		&RightBlock {
			margin-bottom: 10px;
		}
	}

	.button {
		margin-top: 20px;
		height: 37px;
	}
}