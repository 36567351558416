.PersonalOrderItemPhotoReports {
  &_popup {
    width: auto !important;
    height: auto;
  }

  &_reports {
    font-size: 0.8rem;
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    margin: 0 0 25px;

    &-date {
      padding: 0 6px;
      height: 60px;
      min-width: 110px;
      position: relative;
      cursor: pointer;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        padding: 10px 20px;
        margin: 5px 0;
        background-color: #fff;
        box-shadow: 0px 10px 20px rgba(5, 103, 208, 0.2);
        font-size: .8rem;
        position: absolute;
        width: 90%;
        z-index: 100;

        &.reported {
          * {
            color: #fff !important;
            //position: relative;
          }

          background-color: #8864DA;
          box-shadow: 0px 20px 20px rgba(5, 103, 208, 0.2);

          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 86%;
            height: 8px;
            background-color: #8864DA;
            clip-path: polygon(0 0, 50% 100%, 100% 0);
            bottom: -8px;
            animation: slideUpPhotoReports .2s ease-in-out forwards;
          }

          &.active:after {
            height: 0;
            bottom: 0;
            animation: slideDownPhotoReports .2s ease-in-out forwards;
          }

          @keyframes slideDownPhotoReports {
            0% {
              height: 0;
              bottom: 0;
            }
            100% {
              height: 8px;
              bottom: -8px;
            }
          }
          @keyframes slideUpPhotoReports {
            0% {
              height: 8px;
              bottom: -8px;
            }
            100% {
              height: 0;
              bottom: 0;
            }
          }

          .reported-label {
            position: absolute;
            border-radius: 50%;
            width: 22px;
            height: 22px;
            right: -5px;
            top: -5px;

            &.waiting {
              background-color: white;
            }

            &.accepted {
              background-color: #88F61B;
            }

            &.acceptedwithchanges {
              background-color: #FFB800;
            }

            &.expired {
              background-color: #FB3C6C;
            }
          }

        }

      }
    }
  }

  &_currentDay {
    color: #8864DA;
    font-weight: 500;
    padding: 0 .875rem 1rem;
  }

  &_gallery {
    font-size: 0.8rem;
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    margin: 10px 0;

    div {
      position: relative;
      margin-right: 5px;

      img {
        width: 180px;
        height: 130px;
        object-fit: cover;
        cursor: pointer;
      }

      &:before {
        transition: .2s ease-in-out;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        display: block;
        background: rgba(136, 100, 218, 0);
        cursor: pointer;
      }

      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 26px;
        height: 26px;
        z-index: 999;
        opacity: 0;
        fill: #fff;
        cursor: pointer;

      }

      &:hover {
        //&:before {
        //  background: rgba(136, 100, 218, .5);
        //}

        svg {
          opacity: 1;
          transition: .2s ease-in-out;
        }
      }

    }
  }

  &_photos {
    height: 130px;
    img{
      border-radius: 10px;
      border: 1px solid lightgrey;
    }

    div {
      font-size: 1rem;
    }
  }

  &_scroll-container {
//background: #61dafb;
    height: 120px;

  }
}

