@import "colors";
// SECONDARY
.border-secondary {
  border-color: #6c757d !important;
}

.border-gray {
  border-color: #DDDDDD !important;
}


.border-main-1 {
  border-color: $main-1 !important;
}

.border-main-1-3 {
  border-color: $main-1-3 !important;
}

.border-main-2 {
  border-color: $main-2 !important;
}
.border-main-2-1 {
  border-color: $main-2-1 !important;
}

.border-main-3 {
  border-color: $main-3 !important;
}

.border-main-4 {
  border-color: $main-4 !important;
}

.border-main-5 {
  border-color: $main-5 !important;
}

.border-tranche-1 {
  border-color: #D7EECA!important;
}

.border-tranche-2 {
  border-color: #B6B9D4!important;
}
.border-blue{
  border-color: #3F51B5!important;
}
