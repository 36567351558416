.OnlineTablo {
	height: 360px;
	width: 100%;
	background: #ffffff;
	padding: 37px 53px;

	@media (max-width: 1348px) {
		height: auto;
		&__digits {
			width: 1005px;
			justify-content: center;
			&:not(:first-child) {
				margin-top: 20px;
			}
		}
	}
	@media screen and (max-width: 575px) {
		padding: 25px;
	}

	&__title {
		font-weight: bold;
		font-size: 24px;
		line-height: 44px;
		color: #000000;
		margin-bottom: -20px;
		@media screen and (max-width: 575px) {
			font-size: 30px;
			line-height: 36px;
			text-align: center;
		}
    @media (max-width: 400px) {
      font-size: 26px;
    }
	}

	&__row {
		&-title {
			font-size: 24px;
			line-height: 22px;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: #8594a2;
			margin: 35px auto 25px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			font-weight: 300;
			@media screen and (max-width: 575px) {
				font-size: 20px;
				line-height: 30px;
				margin: 25px auto 10px;
				svg {
					height: 25px;
					width: 25px;
				}
			}
			&-line {
				background: #8594a2;
				opacity: 0.4;
				border-radius: 10px;
				flex: 1 0 auto;
				height: 1px;
			}
		}
	}

	&__digits {
		display: flex;
		align-items: center;

		@media screen and (max-width: 575px) {
			&:not(:first-child) {
				margin-top: 10px;
			}
		}

		> div {
			border: 1px solid #8594a2;
			color: #8594a2;
		}

		&.danger {
			> div {
				border: 1px solid #ff1414;
				color: #ff1414;
			}
		}

		&.success {
			> div {
				border: 1px solid #88f61b;
				color: #88f61b;
			}
		}

		&-cell {
			border-radius: 10px;
			width: 110px;
			height: 35px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 600;
			font-size: 12px;
			line-height: 22px;
			text-transform: uppercase;
			@media screen and (max-width: 575px) {
				width: 100px;
				height: 30px;
				font-size: 12px;
				line-height: 20px;
			}
		}

		&-num {
			width: 28px;
			margin: 0 0 0 8px;
			font-size: 20px;
			line-height: 44px;
			@media screen and (max-width: 575px) {
				margin: 0 0 0 6px;
				width: 30px;
			}
		}
	}
}
