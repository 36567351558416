@import "../../../../../../scss/colors";

.ConfirmOrderTranches {
  position: relative;
  z-index: 99;
  display: flex;
  padding: 30px 0;

  //strong, span {
  //  color: currentColor;
  //}

  &_LeftBlock {
    min-width: 20%;
    font-size: 1rem;
    margin-top: 40px;
    @media screen and(max-width: 767px) {
      font-size: .8rem;
      min-width: 50%;
    }
    &__title{
      font-weight: 600;
      font-size: .8rem;
      color: $main-4;
    }

    &-leftHeadingRotate {
      position: absolute;
      left: -30px;
      width: 30px;
      height: 100%;

      &.top {
        align-self: flex-end;
        border-top: 1px solid $main-2-1;
        border-right: 1px solid $main-2-1;
        border-bottom: 1px solid $main-2-1;
      }

      &.bottom {
        align-self: flex-start;
        border-bottom: 1px solid $main-2-1;
        border-right: 1px solid $main-2-1;
      }

      > div {
        width: 100%;
        height: 100%;
        padding: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;

        > span {
          letter-spacing: .5px;
          font-weight: 500;
          font-size: .8rem;
          writing-mode:vertical-rl;
          transform: rotate(180deg);
          text-align: center;
        }
      }
    }
  }

  &_RightBlock {
    padding-top: 40px;
    width: 70%;
    font-size: 0.8rem;
    display: flex;
    flex: 1 0 auto;
    margin-top: 14px;
    //overflow: hidden;
    //overflow-x: scroll;
    //cursor: grab;


    &__item {
      position: relative;
      min-width: 130px;
      display: flex;
      flex-direction: column;

      &.active{
        .ConfirmOrderTranches_RightBlock__item_head{
          background-color: rgba(136,246,27, .2);
          &-label{
            position: absolute;
            top: -34px;
            height: 34px;
            width: 100%;
            font-weight: 500;
            color: #292929 !important;
          }
        }
      }

      &_head {
        padding: 0 6px;
        height: 70px;
        position: relative;

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          padding: 6px 18px;
          background-color: #fff;
          box-shadow: 0px 10px 20px rgba(5, 103, 208, 0.2);
          font-size: .8rem;
          position: absolute;
          width: 90%;
         *{
           color: $black-font!important;
         }
        }
      }

    }

  }

}


