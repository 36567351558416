@import "../../../../../scss/colors";

.PersonalOrderItemMaterial {

  min-width: 992px;

  table {
    width: 100%;
  }

  th {
    color: $main-1-2;
    font-size: .9rem;
    min-width: 100px;

    &:first-child {
      min-width: 320px;
    }

    &:nth-child(2) {
      min-width: 320px;
    }

  }

  tbody {
    tr {
      &:not(:last-child) {
        border-bottom: 1px solid $main-1-3;
      }
    }

    td {
     vertical-align: top;
      >div{
        font-size: .9rem;
        font-weight: 500;
      }
    }
  }


  &_goodImg {
    width: 70px;
    height: 70px;
    object-fit: contain;
    cursor: pointer;
  }
}