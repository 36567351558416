.Personal {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content {
    //padding: 100px 0 50px;
    padding-top: 70px;
    padding-bottom: 40px;
  }
}

