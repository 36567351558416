@font-face {
  font-family: 'Tahoma';
  src: url('../fonts/tahoma/Tahoma.eot');
  src: local('Tahoma'),
  url('../fonts/tahoma/Tahoma.eot?#iefix') format('embedded-opentype'),
  url('../fonts/tahoma/Tahoma.woff') format('woff'),
  url('../fonts/tahoma/Tahoma.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'PF DinDisplay Pro';
  src: url('../fonts/PFDinDisplayPro/PFDinDisplayPro-Regular.eot');
  src: local('../fonts/PFDinDisplayPro/PFDinDisplayPro-Regular'),
  url('../fonts/PFDinDisplayPro/PFDinDisplayPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFDinDisplayPro/PFDinDisplayPro-Regular.woff') format('woff'),
  url('../fonts/PFDinDisplayPro/PFDinDisplayPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'PF DinDisplay Pro';
  src: url('../fonts/PFDinDisplayPro/PFDinDisplayPro-Bold.eot');
  src: local('../fonts/PFDinDisplayPro/PFDinDisplayPro-Bold'),
  url('../fonts/PFDinDisplayPro/PFDinDisplayPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFDinDisplayPro/PFDinDisplayPro-Bold.woff') format('woff'),
  url('../fonts/PFDinDisplayPro/PFDinDisplayPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PF DinDisplay Pro';
  src: url('../fonts/PFDinDisplayPro/PFDinDisplayPro-Medium.eot');
  src: local('PFDinDisplayPro-Medium'),
  url('../fonts/PFDinDisplayPro/PFDinDisplayPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFDinDisplayPro/PFDinDisplayPro-Medium.woff') format('woff'),
  url('../fonts/PFDinDisplayPro/PFDinDisplayPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}



$PFDinDisplayPro: 'PF DinDisplay Pro';

//@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,&display=swap&subset=cyrillic');