.About {
	position: relative;
	margin-top: 90px;

	@media (max-width: 575px) {
		margin-top: 50px;
	}

	&__inner {
		max-width: 1070px;
		width: 100%;
		margin: 0 auto;
    position: relative;
    z-index: 1;
	}

	&__item {
		display: flex;
		align-items: center;

    &-video {
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
    }
	}

	&__title {
		font-style: normal;
		font-weight: bold;
		font-size: 23px;
		line-height: 30px;
		max-width: 595px;
		text-align: center;
		@media screen and (max-width: 575px) {
			font-size: 16px;
			width: 100%;
			line-height: 26px;
		}

		p {
			margin-bottom: 25px;
		}
	}

	&__video {
		min-height: 233px;
		height: 233px;
		width: 367px;
		min-width: 367px;
		border-radius: 20px;
		overflow: hidden;
    position: relative;

		@media screen and (max-width: 575px) {
			width: 100%;
		}

		@media (max-width: 400px) {
			min-width: 1px;
			max-width: 100%;
		}

    &-title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #303030;
    }
	}
}

@media (max-width: 991px) {
  .About {
    &__title {
      font-size: 18px;
      text-align: center;
    }
    &__item-video {
      align-items: flex-end;
      margin-top: 20px;
    }
		&__video-title {
			margin-top: 8px;
		}
  }
}